import { connect } from 'react-redux';
import { get, isEqual, pick } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';
import { formatNewItemsOfDropdownSelection } from '@commons/utils/format';

import centralService from '@services/central';
import recipeService from '@services/recipe';

import { getEnableRecipeModalParams } from '@admin/products/recipes/utils/modalConfigurations';
import { getIsInCentralKitchenOnlyPage } from '@admin/utils/centralKitchen';

import { formatCompositions } from '../RecipeComposition/utils/format';

import { Container } from './styledComponents';

/**
 * Assert composition of the recipe is valid
 *
 * @param {Array} compositions  - The components contained in the recipe
 *
 * @returns {boolean} Whether the form is valid
 */
export const areCompositionsValid = (compositions) =>
  !compositions.some(
    (composition) =>
      composition.entity.id === null || !composition.quantity || !composition.entity.unit,
  );

/**
 * Assert that the general form of that page can be saved (recipe)
 *
 * @param {Object} ingredient  - The recipe to save
 * @param {Array} composition  - The components contained in the recipe
 *
 * @returns {boolean} Whether the form is valid
 */
export const isFormValid = (recipe, compositions, clientHasMultipleBrands) => {
  if (
    !recipe.name ||
    !recipe.categoryId ||
    !recipe.value ||
    !recipe.unit ||
    !compositions.length ||
    (clientHasMultipleBrands && !recipe.brands)
  ) {
    return false;
  }

  return areCompositionsValid(compositions);
};

const _hasFormChanged = (
  recipe,
  initialRecipe,
  compositions,
  initialCompositions,
  productionStoreEntityMappings,
  initialProductionStoreEntityMappings,
  clientHasMultipleChannels,
) => {
  const recipeFieldsToPick = [
    'img',
    'name',
    'value',
    'unit',
    'recipePreparationSteps',
    'brands',
    'inventoryListTemplates',
    'hasStock',
    'batchProduction',
    'hasLoss',
    'categoryId',
  ];

  if (clientHasMultipleChannels) {
    recipeFieldsToPick.push('hasMultipleChannels');
  }

  const hasRecipeInfoChanged = !isEqual(
    pick(recipe, recipeFieldsToPick),
    pick(initialRecipe, recipeFieldsToPick),
  );

  const formattedCompositionById = compositions.reduce(
    (acc, { entity: { id, name }, quantity, channelIds }) => {
      acc[id] = { name, quantity, channelIds };
      return acc;
    },
    {},
  );

  const formattedInitialCompositionById = initialCompositions.reduce(
    (acc, { entity: { id, name }, quantity, channels }) => {
      const channelIds = channels.map(({ id }) => id);

      acc[id] = { name, quantity, channelIds };

      return acc;
    },
    {},
  );

  const hasCompositionsChanged = !isEqual(
    formattedCompositionById,
    formattedInitialCompositionById,
  );

  if (productionStoreEntityMappings == null || initialProductionStoreEntityMappings == null) {
    return hasRecipeInfoChanged || hasCompositionsChanged;
  }

  const hasProductionChanged = !isEqual(
    productionStoreEntityMappings.map(({ storeId }) => storeId),
    initialProductionStoreEntityMappings.map(({ storeId }) => storeId),
  );

  return hasRecipeInfoChanged || hasCompositionsChanged || hasProductionChanged;
};

export const createRecipe = async (
  clientId,
  recipe,
  compositions,
  showMessage,
  history,
  dispatchers,
) => {
  dispatchers.savingInProgress();

  const {
    img,
    name,
    value,
    unit,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    recipePreparationSteps,
    brands,
    inventoryListTemplates,
    categoryId,
  } = recipe;

  try {
    const brandIds = brands.map(({ id }) => id);

    const payload = {
      img,
      name,
      unit,
      value,
      hasMultipleChannels,
      hasStock,
      hasLoss,
      batchProduction,
      compositions,
      recipePreparationSteps,
      brandIds,
      inventoryListTemplates: formatNewItemsOfDropdownSelection(inventoryListTemplates),
      categoryId,
    };

    getIsInCentralKitchenOnlyPage()
      ? await centralService.createKitchenRecipe(clientId, payload)
      : await recipeService.create(clientId, payload);

    showMessage(i18next.t('ADMIN.RECIPES.CREATE_SUCCESS'));

    history.goBack();
  } catch (err) {
    showMessage(i18next.t('ADMIN.RECIPES.CREATE_FAILURE'), 'error');
  } finally {
    dispatchers.savingFinished();
  }
};

export const updateRecipe = async (
  recipe,
  compositions,
  productionStoreEntityMappings,
  showMessage,
  history,
  dispatchers,
) => {
  dispatchers.savingInProgress();

  const {
    id,
    img,
    recipePreparationSteps,
    name,
    value,
    unit,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    brands,
    inventoryListTemplates,
    categoryId,
  } = recipe;

  const storeIdsToBeMapped = productionStoreEntityMappings.map(({ storeId }) => storeId);

  try {
    const brandIds = brands.map(({ id }) => id);

    const payload = {
      id,
      img,
      name,
      unit,
      value,
      hasMultipleChannels,
      hasStock,
      hasLoss,
      batchProduction,
      compositions,
      recipePreparationSteps,
      brandIds,
      inventoryListTemplates: formatNewItemsOfDropdownSelection(inventoryListTemplates),
      categoryId,
    };

    getIsInCentralKitchenOnlyPage()
      ? await centralService.patchKitchenRecipeById({
          ...payload,
          storeIds: storeIdsToBeMapped,
        })
      : await recipeService.patchById(payload);

    showMessage(i18next.t('ADMIN.RECIPES.MODIFY_SUCCESS'));

    history.goBack();
  } catch {
    showMessage(i18next.t('ADMIN.RECIPES.MODIFY_FAILURE'), 'error');
  } finally {
    dispatchers.savingFinished();
  }
};

/**
 * Handle the save of the entire form (recipe)
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {void}
 */
export const handleSave = async (props) => {
  const {
    user,
    recipe,
    showMessage,
    history,
    savingInProgress,
    savingFinished,
    recipeComponentsForm,
    productionStoreEntityMappings,
  } = props;

  const { recipeComponents: formEntities } = recipeComponentsForm.getValues();

  const compositions = formatCompositions(formEntities);

  const formattedCompositions = compositions.map(
    ({ entity: { id: entityId, category, name }, quantity, channelIds, allergens }) => ({
      entityId,
      quantity,
      channelIds,
      allergens,
      category,
      name,
    }),
  );

  if (recipe.id) {
    return updateRecipe(
      recipe,
      formattedCompositions,
      productionStoreEntityMappings,
      showMessage,
      history,
      {
        savingInProgress,
        savingFinished,
      },
    );
  }

  await createRecipe(get(user, 'clientId'), recipe, formattedCompositions, showMessage, history, {
    savingInProgress,
    savingFinished,
  });
};

export const RecipeDetailsBottomBar = (props) => {
  const {
    recipe,
    compositions,
    isReadOnly,
    isSaveDisabled,
    onRecipeChange,
    openGenericModal,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    initialCompositions,
    initialRecipe,
    initialProductionStoreEntityMappings,
    productionStoreEntityMappings,
    clientHasMultipleBrands,
    clientHasMultipleChannels,
  } = props;

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    const formIsValid = isFormValid(recipe, compositions, clientHasMultipleBrands);

    const formHasChanged = _hasFormChanged(
      recipe,
      initialRecipe,
      compositions,
      initialCompositions,
      productionStoreEntityMappings,
      initialProductionStoreEntityMappings,
      clientHasMultipleChannels,
    );

    setFormIsValid(!isSaveDisabled && formIsValid && formHasChanged);
  }, [recipe, compositions, isSaveDisabled, productionStoreEntityMappings]);

  const enableRecipe = async (recipeToEnable) => {
    const recipeToEnableId = recipeToEnable.id;

    pageLoading();

    try {
      await recipeService.enableRecipe(recipeToEnableId);
      onRecipeChange({ ...recipe, active: true });
      showSuccessMessage(i18next.t('ADMIN.RECIPES.ENABLE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.RECIPES.ENABLE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const isEnableButtonDisabled = compositions.some(({ entity: { active } }) => !active);

  if (recipe.active || !recipe.id) {
    return (
      <Container>
        <Button
          buttonCustomStyle={{ margin: '8px 0 0 0' }}
          color={'inpulse-default'}
          handleClick={() => formIsValid && handleSave(props)}
          icon={'/images/inpulse/save-white-small.svg'}
          isDisabled={!formIsValid || isReadOnly}
          label={i18next.t('GENERAL.SAVE')}
        />
      </Container>
    );
  }

  if (isEnableButtonDisabled) {
    return (
      <Container>
        <Tooltip
          displayBigger={true}
          place={'left'}
          renderTooltipTrigger={() => (
            <Button
              buttonCustomStyle={{ margin: '8px 0 0 0' }}
              color={'inpulse-default'}
              handleClick={() => {}}
              icon={'/images/inpulse/power-white-small.svg'}
              isDisabled={true}
              label={i18next.t('GENERAL.ACTIVATE')}
            />
          )}
          text={i18next.t('ADMIN.RECIPES.ENABLED_DISABLE_TOOLTIP')}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Button
        buttonCustomStyle={{ margin: '8px 0 0 0' }}
        color={'inpulse-default'}
        handleClick={() => {
          const params = getEnableRecipeModalParams(recipe, enableRecipe);
          openGenericModal(params);
        }}
        icon={'/images/inpulse/power-white-small.svg'}
        label={i18next.t('GENERAL.ACTIVATE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  savingInProgress: () => {
    dispatch(loading());
  },
  savingFinished: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDetailsBottomBar);
