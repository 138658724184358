import { INVENTORY_LIST_TEMPLATE_TYPE } from './constants';

export const handleNewDataCreation = (
  item,
  type,
  { createdInventoryListTemplates, setCreatedInventoryListTemplates },
) => {
  const dataToUpdate = {
    [INVENTORY_LIST_TEMPLATE_TYPE.INVENTORY_LIST_TEMPLATE]: {
      data: createdInventoryListTemplates,
      setter: setCreatedInventoryListTemplates,
    },
  };

  const currentDataToUpdate = dataToUpdate[type];
  currentDataToUpdate.setter([...currentDataToUpdate.data, item]);
};
