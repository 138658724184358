import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { DropdownModalContainer, ModalContainer } from '../styledComponents';
import Text, { ENUM_FONTS } from '@commons/Text';

const StorageAreaToMapSelectionModalContent = (props) => {
  const { availableStorageAreas, storageAreasToMap, setStorageAreasToMap, isDissociation } = props;

  return (
    <ModalContainer>
      <Text font={ENUM_FONTS.TEXT_BIG_HEIGHT_24}>
        {i18next.t(
          `ADMIN.STORES.${
            isDissociation
              ? 'SSPM_ACTION_REMOVE_FROM_SA_MODAL_TEXT'
              : 'SSPM_ACTION_ADD_TO_SA_MODAL_TEXT'
          }`,
        )}
      </Text>
      <DropdownModalContainer>
        <Dropdown
          customStyle={{ position: 'inherit' }}
          height={'64px'}
          isUniqueSelection={false}
          items={availableStorageAreas}
          label={i18next.t('FEATURE.ADMIN.STORAGE_AREAS')}
          selectedItems={storageAreasToMap}
          isRequired
          isSearchDisabled
          onSelectionChange={setStorageAreasToMap}
        />
      </DropdownModalContainer>
    </ModalContainer>
  );
};

export const getStorageAreaToMapParamModalConfig = ({
  handleSave,
  availableStorageAreas,
  storageAreasToMap,
  setStorageAreasToMap,
  isDissociation,
}) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  icon: `/images/inpulse/${isDissociation ? 'remove-black-small.svg' : 'add-black-small.svg'}`,
  title: i18next.t(
    `ADMIN.STORES.${isDissociation ? 'SSPM_ACTION_REMOVE_FROM_SA' : 'SSPM_ACTION_ADD_TO_SA'}`,
  ),
  component: StorageAreaToMapSelectionModalContent,
  data: {
    availableStorageAreas,
    storageAreasToMap,
    setStorageAreasToMap,
    isDissociation,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled: !storageAreasToMap.length,
      handleClick: handleSave,
    },
  ],
});

export default {
  getStorageAreaToMapParamModalConfig,
};
