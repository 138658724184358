import moment from 'moment';

import { APIcaller } from './APIcaller';
import axios from '../core/http';

import { config } from '../config';

import { DATE_DISPLAY_FORMATS } from '../commons/DatePickers/constants';

const baseApiUrl = config.baseApiUrl;

const getStoreSupplierProductMappingsOfStore = async (
  storeId,
  startDate = moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  filterByUserCatalog = false,
) =>
  APIcaller.apiGet(
    `/admin/stores/${storeId}/store-supplier-product-mappings?startDate=${startDate}&filterByUserCatalog=${filterByUserCatalog}`,
  );

const getStoreSupplierProductMappingsOfAccountStores = async (accountId, supplierProductId) => {
  const { data } = await axios.get(
    `${baseApiUrl}/admin/accounts/${accountId}/stores/storeSupplierProductMappings`,
    {
      params: {
        supplierProductId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

const getStoreSupplierProductMappingsOfSupplierProducts = async (supplierProductIds) => {
  const filter = {
    where: {
      supplierProductId: { inq: supplierProductIds },
    },
    include: [
      {
        relation: 'lnkSupplierproductStoresupplierproductmappingrel',
        scope: {
          order: 'name ASC',
        },
      },
      {
        relation: 'lnkStoreStoresupplierproductmappingrel',
        scope: {
          order: 'name ASC',
        },
      },
    ],
  };

  const { data } = await axios.get(baseApiUrl + '/storeSupplierProductMappings/', {
    params: {
      filter,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

  return data;
};

const patchStoreSupplierProductMapping = async (mapping) => {
  const { data } = await axios.patch(baseApiUrl + '/storeSupplierProductMappings/', mapping, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

  return data;
};

const getStoreSupplierProductMapping = async (id) => {
  const filter = {
    include: [
      {
        relation: 'lnkStoreStoresupplierproductmappingrel',
        scope: {
          order: 'name ASC',
        },
      },
      {
        relation: 'lnkSupplierproductStoresupplierproductmappingrel',
        scope: {
          order: 'name ASC',
        },
      },
    ],
  };

  const { data } = await axios.get(baseApiUrl + '/StoreSupplierProductMappings/' + id, {
    params: {
      filter,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

  return data;
};

const deleteStoreSupplierProductMapping = async (mappingId) => {
  const { data } = await axios.delete(baseApiUrl + '/storeSupplierProductMappings/' + mappingId, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

  return data;
};

const deleteMultipleStoreSupplierProductMapping = async (
  sspmIds,
  isOnlyDeletingNextAvailabilities = false,
) =>
  APIcaller.apiDelete('/admin/store-supplier-product-mappings', {
    sspmIds,
    isOnlyDeletingNextAvailabilities,
  });

const createMultipleStoreSupplierProductMapping = async (mappings) =>
  APIcaller.apiPost('/admin/store-supplier-product-mappings', { mappings });

const patchStoreSupplierProductMappingByIds = async (mappingIds, fields) =>
  APIcaller.apiPatch('/admin/store-supplier-product-mappings', {
    sspmIds: mappingIds,
    fields,
  });

const getByStoreIds = async (storeIds, supplierProductIdsToIgnore = []) =>
  APIcaller.apiPost('/admin/store-supplier-product-mappings/get-by-store-ids', {
    storeIds,
    supplierProductIdsToIgnore,
  });

export const storeSupplierProductMapping = {
  getStoreSupplierProductMappingsOfStore,
  getStoreSupplierProductMappingsOfAccountStores,
  getStoreSupplierProductMappingsOfSupplierProducts,
  patchStoreSupplierProductMapping,
  deleteStoreSupplierProductMapping,
  createMultipleStoreSupplierProductMapping,
  deleteMultipleStoreSupplierProductMapping,
  getStoreSupplierProductMapping,
  patchStoreSupplierProductMappingByIds,
  getByStoreIds,
};
