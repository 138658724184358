import { keyBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { ModalContainer, ModalDropdownItem } from '../styledComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

const StorageAreaToCopySelectionModalContent = (props) => {
  const { availableStorageAreas, storageAreaToCopy, setStorageAreaToCopy, stores } = props;

  const storesKeyById = keyBy(stores, 'id');

  const formattedAvailableStorageAreas = availableStorageAreas.map((storageArea) => ({
    ...storageArea,
    renderValue: () => (
      <ModalDropdownItem>
        <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
          {storageArea.name}
        </Text>
        <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
          {storesKeyById[storageArea.storeId].name}
        </Text>
      </ModalDropdownItem>
    ),
  }));

  return (
    <ModalContainer>
      <Dropdown
        customStyle={{ position: 'inherit' }}
        height={'64px'}
        items={formattedAvailableStorageAreas}
        label={i18next.t('GENERAL.STORAGE_AREA')}
        selectedItem={storageAreaToCopy}
        isRequired
        isSearchDisabled
        isUniqueSelection
        onSelectionChange={setStorageAreaToCopy}
      />
    </ModalContainer>
  );
};

export const getCopyParamModalConfig = ({
  handleParamsCopy,
  availableStorageAreas,
  storageAreaToCopy,
  setStorageAreaToCopy,
  stores,
}) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_MODAL_TITLE'),
  component: StorageAreaToCopySelectionModalContent,
  data: {
    availableStorageAreas,
    storageAreaToCopy,
    setStorageAreaToCopy,
    stores,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => handleParamsCopy(storageAreaToCopy),
    },
  ],
});

export default {
  getCopyParamModalConfig,
};
