import i18next from 'i18next';
import React from 'react';

import { Label } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';
import theme from '@theme';

import { LabelContainer } from '../styledComponents';

const renderBooleanLabel = (isUsed) => (
  <LabelContainer>
    <Label
      background={isUsed ? theme.colors.infoGreen : theme.colors.infoRed}
      color={isUsed ? theme.colors.infoGreen : theme.colors.infoRed}
      type={null}
    >
      {i18next.t(isUsed ? 'GENERAL.YES' : 'GENERAL.NO')}
    </Label>
  </LabelContainer>
);

const renderNumberLabel = (count) => (
  <LabelContainer>
    <Label
      background={count ? theme.colors.infoGreen : theme.colors.infoRed}
      color={count ? theme.colors.infoGreen : theme.colors.infoRed}
      type={null}
    >
      {count}
    </Label>
  </LabelContainer>
);

export const getListColumns = () => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    displayBigger: true,
  },
  {
    id: 'category',
    propertyKey: 'category',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_CATEGORY'),
    filterType: 'string',
    filterStringAllowNone: true,
    hidden: true,
  },
  {
    id: 'subCategory',
    propertyKey: 'subCategory',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUB_CATEGORY'),
    filterType: 'string',
    excludeFromSearch: true,
    filterStringAllowNone: true,
  },
  {
    id: 'supplierName',
    propertyKey: 'supplierName',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUPPLIER'),
    filterType: 'string',
    excludeFromSearch: true,
    filterStringAllowNone: true,
  },
  {
    id: 'price',
    propertyKey: 'price',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_PRICE_TAX_EXCLUDED'),
    type: 'currency',
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    id: 'available',
    propertyKey: 'available',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_AVAILABILITY'),
    excludeFromSearch: true,
    render: renderBooleanLabel,
  },
  {
    id: 'hasStock',
    propertyKey: 'hasStock',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_STOCKS'),
    excludeFromSearch: true,
    render: renderBooleanLabel,
  },
  {
    id: 'hasDlc',
    propertyKey: 'hasDlc',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_LOSS'),
    excludeFromSearch: true,
    render: renderBooleanLabel,
  },
  {
    id: 'associated-storage-areas',
    propertyKey: 'associatedStorageAreaIdsCount',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_STORAGE_AREAS'),
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (item) => renderNumberLabel(item),
  },
];
