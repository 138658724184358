import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';

import normalizeStringValue from '@commons/utils/normalizeStringValue';

import clientService from '@services/client';

import {
  DYNAMIC_MODAL_ALREADY_USED,
  DYNAMIC_PROPERTIES,
} from '@admin/commons/dynamicModalProperties';

import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { getAddNewItemModalParams } from '../../utils/modalConfigurations';

import { Container, FormContainer, Section, SectionInfo, Title } from './styledComponents';
import {
  RecipeAllergensInput,
  RecipeBrandsInput,
  RecipeCategoryInput,
  RecipeInventoryTemplatesInput,
  RecipeNameInput,
  RecipeQuantityInput,
  RecipeUnitInput,
  RecipeToggles,
} from './common/inputs';

import { getClientInfo } from '@selectors/client';

import { loading, loadingSuccess } from '@actions/loading';

import { CATEGORY_TYPES_OBJECT, getPropertyNoneValue } from '@commons/constants/categoryTypes';

/**
 * Get the custom list of inputs to allow in the form according the client settings
 *
 * @param {boolean} clientHasMultipleChannels
 *
 * @returns {Inputs[]} The list of custom inputs to display
 */
export function getCustomListInputs(clientHasMultipleBrands) {
  if (clientHasMultipleBrands) {
    return [
      [RecipeNameInput, RecipeBrandsInput, RecipeCategoryInput],
      [RecipeQuantityInput, RecipeUnitInput, RecipeAllergensInput],
      [RecipeInventoryTemplatesInput, RecipeToggles],
    ];
  }

  return [
    [RecipeNameInput, RecipeCategoryInput, RecipeQuantityInput],
    [RecipeUnitInput, RecipeAllergensInput, RecipeInventoryTemplatesInput],
    [RecipeToggles],
  ];
}

/**
 * Render the recipe form section with the different inputs that should be displayed
 *
 * @param {Props} props                     - The props sent to method
 * @param {Recipe} props.recipe             - The recipe's data that should be displayed
 * @param {Function} props.onRecipeChange   - Method used to set the local state of the recipe variable
 */
export function renderForm(props) {
  const { clientHasMultipleBrands } = props;
  const recipeFieldsFormBySection = getCustomListInputs(clientHasMultipleBrands);

  return (
    <div>
      {recipeFieldsFormBySection.map((section, indexSection) => (
        <Section key={indexSection}>
          {section.map((item, indexItem) => {
            const ComponentName = item.component;
            const inputProps = item.props(props);

            return (
              <SectionInfo key={indexItem}>
                <ComponentName {...inputProps} />
              </SectionInfo>
            );
          })}
        </Section>
      ))}
    </div>
  );
}

/*************************/
/* Main Component Method */
/*************************/

export function RecipeInfoSection(props) {
  const {
    clientHasMultipleChannels,
    clientHasMultipleBrands,
    recipe,
    recipeCost,
    onRecipeChange,
    setRecipeCategories,
    recipeCategories,
    allergens,
    isReadOnly,
    openGenericModal,
    refreshGenericModal,
    isKitchen,
    brands,
    inventoryListTemplates,
    setInventoryListTemplates,
    client: { clientId },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  // For Category/InventoryTemplates on-the-fly creation modal
  const [inputValue, setInputValue] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Make sure modal and its content is up-to-date when entering input
  useEffect(() => {
    refreshGenericModal(
      getAddNewItemModalParams({
        propertyName,
        inputValue,
        errorMessage,
        closeCleanUp,
        handleInputChange,
        handleSaveNewItemInDropdown,
      }),
    );
  }, [inputValue]);

  const closeCleanUp = () => {
    setErrorMessage('');
    setInputValue('');
  };

  const openAddNewItemModal = (propertyName) => {
    setPropertyName(propertyName);

    openGenericModal(
      getAddNewItemModalParams({
        propertyName,
        inputValue,
        errorMessage,
        closeCleanUp,
        handleInputChange,
        handleSaveNewItemInDropdown,
      }),
    );
  };

  /**
   * In the on-the-fly creation modal, check if the input matches with an already existing value
   * @param {*} propertyName one of the DYNAMIC_PROPERTIES
   * @param {*} newValue the user's input
   */
  const handleInputChange = (propertyName, newValue) => {
    setInputValue(newValue);

    if (!newValue) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const dropdownItems = {
      [DYNAMIC_PROPERTIES.CATEGORY]: recipeCategories,
      [DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES]: inventoryListTemplates,
    };

    const selectedDropdownItems = dropdownItems[propertyName];

    const alreadyExists = selectedDropdownItems.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(newValue),
    );

    if (alreadyExists) {
      setErrorMessage(i18next.t(DYNAMIC_MODAL_ALREADY_USED[propertyName]));

      return;
    }

    setErrorMessage('');
  };

  /**
   * Handles the creation of a new category.
   *
   * It updates the recipe categories and the current recipe with the newly created category.
   * If an error occurs during the creation process, it sets the recipe category to a default "None" value.
   *
   * @async
   * @function handleNewCategoryCreation
   * @returns {Promise<void>} A promise that resolves when the category creation process is complete.
   */
  const handleNewCategoryCreation = async () => {
    try {
      pageLoading();

      const createdCategory = await clientService.createCategory(
        clientId,
        inputValue.trim(),
        isKitchen ? CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_RECIPE : CATEGORY_TYPES_OBJECT.RECIPE,
      );

      const updatedCategories = sortBy([...recipeCategories, createdCategory], 'name');

      setRecipeCategories(updatedCategories);

      onRecipeChange({
        ...recipe,
        category: createdCategory.name,
        categoryId: createdCategory.id,
      });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      const categoryNone = recipeCategories.find(({ name }) => name === getPropertyNoneValue());

      onRecipeChange({
        ...recipe,
        category: categoryNone.name,
        categoryId: categoryNone.id,
      });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  /**
   * In the on-the-fly creation modal, when saving,
   * - add the new item to the list of values
   * - update the recipe's property to inclue the new item's value
   */
  const handleSaveNewItemInDropdown = async () => {
    switch (propertyName) {
      case DYNAMIC_PROPERTIES.CATEGORY:
        await handleNewCategoryCreation();
        break;
      case DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES:
        const newILT = { id: inventoryListTemplates.length, name: inputValue.trim() };

        const updatedInventoryListTemplates = sortBy([...inventoryListTemplates, newILT], 'name');

        setInventoryListTemplates(updatedInventoryListTemplates);

        onRecipeChange({
          ...recipe,
          [DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES]: [
            ...recipe[DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES],
            newILT,
          ],
        });
        break;

      default:
        return;
    }

    setInputValue('');
    setErrorMessage('');
  };

  return (
    <Container>
      <Title>{i18next.t('ADMIN.RECIPES.TYPE_RECIPES')}</Title>
      <FormContainer>
        {renderForm({
          recipe,
          recipeCost,
          onRecipeChange,
          recipeCategories,
          allergens,
          isReadOnly,
          clientHasMultipleChannels,
          clientHasMultipleBrands,
          isKitchen,
          brands,
          inventoryListTemplates,
          openAddNewItemModal,
        })}
      </FormContainer>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeInfoSection);
