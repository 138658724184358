import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// Commons
import { getTheme } from '@commons/utils/theme';
import { ListView, Dropdown } from '@commons/utils/styledLibraryComponents';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

// Utils
import { getColumns } from '../mappings.utils';

// Styling
import { Container, HeaderContainer, DropdownItem } from './styledComponents';

const AssociateSupplierProductsModal = (props) => {
  const { hasLocalCatalogs, setSpToBeAdded, availableSPForAssociation } = props;

  const theme = getTheme();

  const [columns] = useState(getColumns(hasLocalCatalogs, true));

  const [supplierProducts, setSupplierProducts] = useState([]);
  const [filteredSupplierProducts, setFilteredSupplierProducts] = useState([]);

  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  useEffect(() => {
    formatSupplierProducts();
  }, []);

  useEffect(() => {
    const selectedSupplierIdsSet = new Set(selectedSuppliers.map(({ id }) => id));
    const selectedCategoryIdsSet = new Set(selectedCategories.map(({ id }) => id));
    const selectedSubCategoryIdsSet = new Set(selectedSubCategories.map(({ id }) => id));

    const filteredSps = supplierProducts.filter(
      ({ supplierId, categoryId, subCategoryId }) =>
        selectedSupplierIdsSet.has(supplierId) &&
        selectedCategoryIdsSet.has(categoryId) &&
        selectedSubCategoryIdsSet.has(subCategoryId),
    );

    setFilteredSupplierProducts(filteredSps);
  }, [selectedSuppliers, selectedCategories, selectedSubCategories]);

  const formatSupplierProducts = async () => {
    const { formattedSupplierProducts, categoriesKeyById, subCategoriesKeyById, suppliersKeyById } =
      availableSPForAssociation.reduce(
        (acc, supplierProduct) => {
          const category = supplierProduct.category || i18next.t('GENERAL.NONE_VALUE');
          const subCategory = supplierProduct.subCategory || i18next.t('GENERAL.NONE_VALUE');

          if (!acc.categoriesKeyById[supplierProduct.categoryId]) {
            acc.categoriesKeyById[supplierProduct.categoryId] = {
              id: supplierProduct.categoryId,
              name: category,
            };
          }

          if (!acc.subCategoriesKeyById[supplierProduct.subCategoryId]) {
            acc.subCategoriesKeyById[supplierProduct.subCategoryId] = {
              id: supplierProduct.subCategoryId,
              name: subCategory,
            };
          }

          if (!acc.suppliersKeyById[supplierProduct.supplierId]) {
            acc.suppliersKeyById[supplierProduct.supplierId] = {
              id: supplierProduct.supplierId,
              name: supplierProduct.supplierName,
              ...(hasLocalCatalogs && {
                catalogName: supplierProduct.catalogName,
                renderValue: () => (
                  <DropdownItem>
                    <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                      {supplierProduct.supplierName}
                    </Text>
                    <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
                      {supplierProduct.catalogName}
                    </Text>
                  </DropdownItem>
                ),
              }),
            };
          }

          acc.formattedSupplierProducts.push({
            ...supplierProduct,
            category,
            subCategory,
          });

          return acc;
        },
        {
          formattedSupplierProducts: [],
          categoriesKeyById: {},
          subCategoriesKeyById: {},
          suppliersKeyById: {},
        },
      );

    setSupplierProducts(formattedSupplierProducts);
    setFilteredSupplierProducts(formattedSupplierProducts);

    const formattedSuppliers = Object.values(suppliersKeyById);
    setSuppliers(formattedSuppliers);
    setSelectedSuppliers(formattedSuppliers);

    const formattedCategories = Object.values(categoriesKeyById);
    setCategories(formattedCategories);
    setSelectedCategories(formattedCategories);

    const formattedSubCategories = Object.values(subCategoriesKeyById);
    setSubCategories(formattedSubCategories);
    setSelectedSubCategories(formattedSubCategories);
  };

  const handleSelection = (selectedItems) => {
    const formattedSupplierProducts = selectedItems.map((supplierProduct) => ({
      ...supplierProduct,
      isRowSelected: false,
    }));

    setSpToBeAdded(formattedSupplierProducts);
  };

  return (
    <Container>
      <ListView
        columns={columns}
        data={filteredSupplierProducts}
        defaultMaxPerPage={500}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        markerConfiguration={{
          isHidden: ({ isKitchen }) => !isKitchen,
          backgroundColor: theme.colors.brand.secondary,
          icon: { src: '/images/inpulse/central-black-small.svg' },
        }}
        maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        padding={LISTVIEW_NO_TOP_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <HeaderContainer>
            <Dropdown
              iconSrc={'/images/inpulse/supplier-black-small.svg'}
              isUniqueSelection={false}
              items={suppliers}
              selectedItems={selectedSuppliers}
              isRequired
              onSelectionChange={setSelectedSuppliers}
            />
            <Dropdown
              iconSrc={'/images/inpulse/category-ipblack-small.svg'}
              isUniqueSelection={false}
              items={categories}
              selectedItems={selectedCategories}
              isRequired
              onSelectionChange={setSelectedCategories}
            />
            <Dropdown
              iconSrc={'/images/inpulse/sub-category-ip-black-small.svg'}
              isUniqueSelection={false}
              items={subCategories}
              selectedItems={selectedSubCategories}
              isRequired
              onSelectionChange={setSelectedSubCategories}
            />
          </HeaderContainer>
        )}
        setSelectedItems={handleSelection}
        forceEnableSelection
        hideAllPerPageOption
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(AssociateSupplierProductsModal);
