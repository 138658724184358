import styled from 'styled-components';

export const LabelContainer = styled.div`
  min-width: 28px;
  display: flex;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DropdownModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
