import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView, Dropdown } from '@commons/utils/styledLibraryComponents';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import useLocalStorage from '@hooks/useLocalStorage';

import { canCreateStorageArea } from '@selectors/actions/storageAreaActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import storageAreaService from '@services/storageArea';

import { Container, ListViewContainer, HeaderContainer } from './styledComponents';
import actionsUtils from './utils/actionsUtils';
import columnsUtils from './utils/columnsUtils';
import exportUtils from './utils/exportUtils';
import modalsUtils from './utils/modalsUtils';

const StorageAreas = (props) => {
  const {
    match: { path },
    history,
    client: { clientId, storeName },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
    refreshGenericModal,
    stores,
    authorizedActions,
  } = props;

  const clientStoreName = getClientStoreNameTranslation(storeName, false);

  const [isInCentralKitchenMode] = useLocalStorage('isCentralMode', false);

  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [columns] = useState(columnsUtils.getListColumns(isInCentralKitchenMode, clientStoreName));
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);

  const [storageAreas, setStorageAreas] = useState([]);
  const [filteredStorageAreas, setFilteredStorageAreas] = useState([]);
  const [selectedStorageAreas, setSelectedStorageAreas] = useState([]);

  const [storageAreaToCopy, setStorageAreaToCopy] = useState(null);

  /** USE EFFECT */

  useEffect(() => {
    (async () => {
      await getStorageAreas();
    })();
  }, []);

  useEffect(() => {
    if (!stores.length) {
      return;
    }

    setSelectedStores(stores);
  }, [stores]);

  useEffect(() => {
    const selectedStoreIdsSet = new Set(selectedStores.map(({ id }) => id));

    const filteredSA = storageAreas.filter(({ storeId }) => selectedStoreIdsSet.has(storeId));

    setFilteredStorageAreas(filteredSA);
  }, [selectedStores, storageAreas]);

  useEffect(() => {
    if (!isLoading) {
      setDisplayEmptyState(!storageAreas.length);
    }
  }, [isLoading, storageAreas]);

  useEffect(() => {
    setActions(
      actionsUtils.getGlobalActions({
        authorizedActions,
        handleCopyParamModal,
        handleCreation: goToCreateStorageArea,
        handleDeletion: handleDeleteStorageAreas,
        handleExport: exportStorageAreas,
        selectedStorageAreas,
        storageAreas: filteredStorageAreas,
      }),
    );

    setRowActions(actionsUtils.getRowActions(handleDeleteStorageAreas, authorizedActions));
  }, [filteredStorageAreas, selectedStorageAreas]);

  useEffect(() => {
    if (!storageAreaToCopy) {
      return;
    }

    handleCopyParamModal(true);
  }, [storageAreaToCopy]);

  /** FUNCTIONS */

  const handleCopyParamModal = (shouldRefreshModal = false) => {
    const selectedStorageAreaIds = new Set(selectedStorageAreas.map(({ id }) => id));

    const params = modalsUtils.getCopyParamModalConfig({
      handleParamsCopy,
      storageAreaToCopy,
      availableStorageAreas: storageAreas.filter(({ id }) => !selectedStorageAreaIds.has(id)),
      setStorageAreaToCopy,
      stores,
    });

    if (shouldRefreshModal) {
      refreshGenericModal(params);
    }

    openGenericModal(params);
  };

  const handleParamsCopy = async () => {
    try {
      await storageAreaService.copyStorageAreaParams(
        storageAreaToCopy.id,
        selectedStorageAreas.map(({ id }) => id),
      );

      setStorageAreaToCopy(null);

      showSuccessMessage(i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_SUCCESS'));

      await getStorageAreas();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_ERROR'));
    }
  };

  const getStorageAreas = async () => {
    pageLoading();

    try {
      const storageAreas = await storageAreaService.getStorageAreasByClientId(clientId, {
        filterByUserCatalog: true,
      });
      const formattedStorageAreas = storageAreas.map(
        ({
          id,
          name,
          supplierProductIds,
          salesPointEntityIds,
          centralKitchenEntityIds,
          storeId,
          storeName,
        }) => ({
          id,
          name,
          associatedSPsCount: supplierProductIds.length,
          associatedSalesPointRecipeCount: salesPointEntityIds.length,
          associatedCentralKitchenRecipeCount: centralKitchenEntityIds.length,
          storeId,
          storeName,
        }),
      );

      setStorageAreas(formattedStorageAreas);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.FETCHING_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const deleteStorageAreas = async (storageAreas) => {
    pageLoading();
    setIsLoading(true);

    try {
      const storageAreaIds = storageAreas.map(({ id }) => id);
      await storageAreaService.disableStorageAreas(clientId, storageAreaIds);

      showSuccessMessage(
        i18next.t('ADMIN.STORAGE_AREAS.DELETION_SUCCESS', { count: storageAreas.length }),
      );

      await getStorageAreas();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.DELETION_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const goToCreateStorageArea = () => {
    history.push('/admin/inventories/storage-areas/create');
  };

  const goToStorageAreaDetailsPage = (storageArea) => {
    history.push(`/admin/inventories/storage-areas/${storageArea.id}/details`);
  };

  const handleDeleteStorageAreas = (items) => {
    const count = items.length;

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count,
      }),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ADMIN.STORAGE_AREAS.DELETE_CONFIRMATION_MESSAGE', {
          count,
          name: get(items, '[0].name', ''),
        }),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: async () => await deleteStorageAreas(items),
        },
      ],
    };
    openGenericModal(params);
  };

  const exportStorageAreas = () => {
    exportUtils.exportStorageAreas(
      selectedStorageAreas.length ? selectedStorageAreas : filteredStorageAreas,
    );
  };

  const renderNoStorageAreas = () => (
    <EmptyState
      label={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_TITLE')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() =>
        canCreateStorageArea(authorizedActions) && (
          <Button
            color={'inpulse-default'}
            handleClick={goToCreateStorageArea}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.CREATE')}
          />
        )
      }
      subtitle={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  return (
    <ListViewContainer>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        {displayEmptyState ? (
          renderNoStorageAreas()
        ) : (
          <ListView
            actionOnClick={goToStorageAreaDetailsPage}
            actions={!isLoading && actions}
            columns={columns}
            data={filteredStorageAreas}
            isLoading={isLoading}
            padding={STANDARD_LISTVIEW_PADDING}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <HeaderContainer>
                <Dropdown
                  iconSrc={'/images/inpulse/store-black-small.svg'}
                  isDisabled={isLoading}
                  isUniqueSelection={false}
                  items={stores}
                  selectedItems={selectedStores}
                  isRequired
                  onSelectionChange={setSelectedStores}
                />
              </HeaderContainer>
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedStorageAreas}
          />
        )}
      </Container>
    </ListViewContainer>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
  authorizedActions: [
    ...getAuthorizedActions(state.baseReducer.userRights, '/admin/inventories/storage-areas'),
    ...getAuthorizedActions(
      state.baseReducer.userRights,
      '/admin/inventories/storage-areas/create',
    ),
  ],
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreas);
