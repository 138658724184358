import { get } from 'lodash';
import i18next from 'i18next';

import { getClientStoreNameTranslation } from '../../utils/translations';

import {
  CashierProduct,
  Ingredient,
  InventoryListTemplate,
  Product,
  Recipe,
  StorageArea,
  Store,
  Supplier,
  SupplierProduct,
  User,
  Catalogs,
} from './subNavigation';
import { getEntityLabelNavbar } from '../index';

const STORES = {
  getText: ({ storeName }) => getClientStoreNameTranslation(storeName, true),
  path: '/admin/stores',
  customRender: ({ storeName }) => ({
    extraText: storeName,
  }),
  subFeatures: [
    Store.DETAILS,
    Store.MAPPED_USERS,
    Store.PRODUCTION_SCHEDULE,
    Store.SUPPLIER_PROFILES,
    Store.SUPPLIER_PRODUCTS,
  ],
};

const CATALOGS = {
  getText: () => i18next.t('FEATURE.ADMIN.CATALOGS'),
  customRender: ({ catalog: { name } }) => ({
    linkedText: i18next.t('FEATURE.ADMIN.CATALOGS'),
    extraText: `/ ${name}`,
  }),
  path: '/admin/catalogs',
  subFeatures: [Catalogs.CATALOG_CREATION, Catalogs.DETAILS, Catalogs.USERS],
  isVisible: ({ hasLocalCatalogs }) => hasLocalCatalogs,
};

const TEAM = {
  getText: () => i18next.t('FEATURE.ADMIN.TEAM'),
  path: '/users/team',
  customRender: ({ user, profileUser }) => {
    if (get(user, 'id') === get(profileUser, 'id')) {
      return null;
    }

    return {
      linkedText: i18next.t('USERS.DETAILS.NAV_BAR_TEAM'),
      extraText: `/ ${profileUser.firstName || '---'} ${profileUser.lastName || '---'}`,
    };
  },
  subFeatures: [User.DETAILS, User.STORES],
};

const MANAGEMENT_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ADMIN.ORGANISATION'),
  icon: '/images/store.svg',
  children: [STORES, CATALOGS, TEAM],
};

const PRODUCTS = {
  getText: () => i18next.t('FEATURE.ADMIN.PRODUCTS'),
  path: '/admin/products/products',
  customRender: ({ product, productId }) => ({
    linkedText: productId && i18next.t('FEATURE.ADMIN.PRODUCTS'),
    extraText: product && product.name && `/ ${product.name}`,
  }),
  subFeatures: [Product.PRODUCT],
};

const RECIPES = {
  getText: () => i18next.t('FEATURE.ADMIN.RECIPES'),
  path: '/admin/products/recipes',
  customRender: ({ recipe }) => ({
    linkedText: recipe && recipe.id && i18next.t('FEATURE.ADMIN.RECIPES'),
    extraText: getEntityLabelNavbar(recipe, i18next.t('ADMIN.RECIPES.NEW_RECIPE')),
  }),
  subFeatures: [Recipe.RECIPE_CREATION, Recipe.RECIPE_DETAILS],
};

const INGREDIENTS = {
  getText: () => i18next.t('FEATURE.ADMIN.INGREDIENTS'),
  path: '/admin/products/ingredients',
  customRender: ({ ingredientId, ingredient }) => ({
    linkedText: ingredientId && i18next.t('FEATURE.BACKOFFICE.INGREDIENTS'),
    extraText: getEntityLabelNavbar(ingredient, i18next.t('ADMIN.INGREDIENTS.NEW_INGREDIENT')),
  }),
  subFeatures: [Ingredient.INGREDIENT_CREATION, Ingredient.INGREDIENT_DETAILS],
};

const PRODUCTS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ADMIN.PRODUCTS'),
  icon: '/images/burger.svg',
  children: [PRODUCTS, RECIPES, INGREDIENTS],
};

const SUPPLIERS = {
  getText: () => i18next.t('FEATURE.ADMIN.SUPPLIERS'),
  path: '/admin/suppliers/suppliers',
  customRender: ({ supplier: { name } }) => ({
    extraText: `/ ${name ? name : ''}`,
    linkedText: i18next.t('FEATURE.ADMIN.SUPPLIERS'),
  }),
  subFeatures: [Supplier.DETAILS, Supplier.SUPPLIER_PROFILES, Supplier.STORES],
};

const SUPPLIER_PRODUCTS = {
  getText: () => i18next.t('FEATURE.ADMIN.SUPPLIERS_PRODUCTS'),
  path: '/admin/suppliers/supplier-products',
  customRender: ({ supplierProduct: { name } }) => ({
    linkedText: i18next.t('FEATURE.ADMIN.SUPPLIERS_PRODUCTS'),
    extraText: `/ ${name ? name : ''}`,
  }),
  subFeatures: [
    SupplierProduct.DETAILS,
    SupplierProduct.STORES,
    SupplierProduct.SUPPLIER_PRODUCT_CREATION,
  ],
};

const SUPPLIERS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ADMIN.SUPPLIERS'),
  icon: '/images/truck.svg',
  children: [SUPPLIERS, SUPPLIER_PRODUCTS],
};

const ADMIN_SALES = {
  getText: () => i18next.t('FEATURE.ADMIN.ACTUAL_SALES'),
  path: '/admin/sales',
};

const SALES_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ADMIN.SALES'),
  path: '/admin/sales',
  icon: '/images/paid.svg',
  children: [ADMIN_SALES],
};

const CASHIER_PRODUCTS = {
  getText: () => i18next.t('FEATURE.ADMIN.CASHIER_PRODUCTS'),
  path: '/admin/cashier/cashier-products',
  subFeatures: [CashierProduct.CASHIER_PRODUCT],
};

const TRANSACTIONS = {
  getText: () => i18next.t('FEATURE.ADMIN.TRANSACTIONS'),
  path: '/admin/cashier/transactions',
};

const FTP_DEBUG = {
  getText: () => i18next.t('FEATURE.ADMIN.FTP_DEBUG'),
  path: '/admin/cashier/sales-monitoring',
};

const MAPPING_MONITORING = {
  getText: () => i18next.t('FEATURE.ADMIN.MAPPING_MONITORING'),
  path: '/admin/cashier/mapping-monitoring',
};

const CASHIER_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ADMIN.CASHIER'),
  icon: '/images/inpulse/cashier.svg',
  children: [CASHIER_PRODUCTS, TRANSACTIONS, FTP_DEBUG, MAPPING_MONITORING],
};

const STORAGE_AREAS = {
  getText: () => i18next.t('FEATURE.ADMIN.STORAGE_AREAS'),
  path: '/admin/inventories/storage-areas',
  customRender: ({ storageArea: { name } }) => ({
    linkedText: i18next.t('FEATURE.ADMIN.STORAGE_AREAS'),
    extraText: `/ ${name ? name : ''}`,
  }),
  subFeatures: [
    StorageArea.DETAILS,
    StorageArea.SUPPLIER_PRODUCTS,
    StorageArea.STORAGE_AREA_CREATION,
    StorageArea.RECIPES,
  ],
};

const INVENTORY_LIST_TEMPLATES = {
  getText: () => i18next.t('FEATURE.ADMIN.INVENTORY_LIST_TEMPLATES'),
  path: '/admin/inventories/inventory-list-templates',
  customRender: ({ inventoryListTemplate: { name } }) => ({
    linkedText: i18next.t('FEATURE.ADMIN.INVENTORY_LIST_TEMPLATES'),
    extraText: `/ ${name ? name : ''}`,
  }),
  subFeatures: [
    InventoryListTemplate.DETAILS,
    InventoryListTemplate.SUPPLIER_PRODUCTS,
    InventoryListTemplate.INVENTORY_LIST_TEMPLATE_CREATION,
    InventoryListTemplate.RECIPES,
  ],
};

const INVENTORIES_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ADMIN.INVENTORIES'),
  icon: '/images/inpulse/stocks.svg',
  children: [STORAGE_AREAS, INVENTORY_LIST_TEMPLATES],
};

export const ADMIN = {
  getText: () => i18next.t('FEATURE.ADMIN.ADMIN'),
  path: '/admin',
  icon: '/images/administration-bis.svg',
  children: [
    MANAGEMENT_SUBMODULE,
    CASHIER_SUBMODULE,
    PRODUCTS_SUBMODULE,
    SUPPLIERS_SUBMODULE,
    INVENTORIES_SUBMODULE,
    SALES_SUBMODULE,
  ],
};
