import _ from 'lodash';
import moment from 'moment';

import { APIcaller } from './APIcaller';

import { config } from '../config';
import axios from '../core/http';

import { DATE_DISPLAY_FORMATS } from '../commons/DatePickers/constants';

const baseApiUrl = config.baseApiUrl;

export const getStoresOfUser = (activeStoresOnly = false) => {
  const userId = localStorage.getItem('userId');
  let filter = {
    order: 'name ASC',
    include: ['lnkBrandStorerel', 'groups'],
  };

  if (activeStoresOnly) {
    _.set(filter, 'where.active', true);
  }

  return axios
    .get(baseApiUrl + '/Accounts/' + userId + '/stores', {
      params: {
        filter,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

export const getById = async (storeId) => APIcaller.apiGet(`/admin/stores/${storeId}`);

export const getEventsOfStore = (storeId, startDate, endDate) => {
  const params = {
    startDate: moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  };

  if (endDate) {
    params.endDate = moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  }

  return APIcaller.apiGet(`/admin/stores/${storeId}/events`, params);
};

export const getEventSubCategories = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/event-sub-categories`);

export const getStoresOfClient = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/stores`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });

export const getAccountsOfStore = (storeId) =>
  axios
    .get(`${baseApiUrl}/admin/stores/${storeId}/accounts`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });

export const patchStore = (storeId, fields) =>
  APIcaller.apiPatch(`/admin/stores/${storeId}/`, { fields });

export const getProductionProductsOfStore = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/products`);

export const updateStoresInBatch = (storeIds, fields) =>
  APIcaller.apiPatch('/admin/stores', { storeIds, fields: { ...fields } });

export const getLastInventoriesByEntityId = (storeId, entityId, date) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/ingredients/${entityId}/inventories?date=${date}`);

export const getStoresByAccountIdAndSupplierId = (accountId, supplierId) =>
  APIcaller.apiPost(`/admin/stores/stores-with-supplier-profile`, { accountId, supplierId });

export const getStoreTransactions = (storeId, startDate, endDate = null) =>
  APIcaller.apiGet(
    `/admin/stores/${storeId}/transactions?startDate=${startDate}${
      endDate ? `&endDate=${endDate}` : ''
    }`,
  );

export const deleteStoreProductMappingsByProductIds = (storeId, productIds) =>
  APIcaller.apiDelete(`/admin/stores/${storeId}/products`, { productIds });

export const updateStoreProductMappingsByProductIds = (storeId, productIds, fields) =>
  APIcaller.apiPatch(`/admin/stores/${storeId}/products`, { productIds, fields });

export const getAvailableProducts = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/available-products`);

export const createStoreProductMappings = (storeId, productIds) =>
  APIcaller.apiPost(`/admin/stores/${storeId}/products`, { productIds });

export const getNotAssociatedSupplierProducts = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/not-associated-supplier-products`);

export default {
  getById,
  getStoresOfClient,
  getStoresOfUser,
  patchStore,
  getAccountsOfStore,
  getProductionProductsOfStore,
  getEventsOfStore,
  getEventSubCategories,
  updateStoresInBatch,
  getLastInventoriesByEntityId,
  getStoresByAccountIdAndSupplierId,
  getStoreTransactions,
  deleteStoreProductMappingsByProductIds,
  updateStoreProductMappingsByProductIds,
  getAvailableProducts,
  createStoreProductMappings,
  getNotAssociatedSupplierProducts,
};
