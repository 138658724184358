import i18next from 'i18next';

import { getClientStoreNameTranslation } from '../../../utils/translations';

export const DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: '/admin/stores/:id/details',
  getLink: ({ storeId }) => `/admin/stores/${storeId}/details`,
};

export const MAPPED_USERS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.MAPPED_USERS'),
  path: '/admin/stores/:id/details-users',
  getLink: ({ storeId }) => `/admin/stores/${storeId}/details-users`,
};

export const PRODUCTION_SCHEDULE = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.PRODUCTION_ASSORTMENTS'),
  path: '/admin/stores/:id/products',
  getLink: ({ storeId }) => `/admin/stores/${storeId}/products`,
};

export const SUPPLIER_PROFILES = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.MAPPED_SUPPLIER_PROFILES'),
  path: '/admin/stores/:id/supplierProfiles',
  getLink: ({ storeId }) => `/admin/stores/${storeId}/supplierProfiles`,
};

export const SUPPLIER_PRODUCTS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.MAPPED_SUPPLIER_PRODUCTS'),
  path: '/admin/stores/:id/supplier-products',
  getLink: ({ storeId }) => `/admin/stores/${storeId}/supplier-products`,
};

export const BACKOFFICE_STORE_DETAILS = {
  getText: ({ storeName }) => getClientStoreNameTranslation(storeName),
  path: '/backoffice/stores/stores/:id/details',
  getLink: ({ storeId }) => `/backoffice/stores/stores/${storeId}/details`,
  isVisible: () => false,
};

export const BACKOFFICE_STORE_CREATION = {
  getText: ({ storeName }) => i18next.t('BACKOFFICE.STORES.CREATION_MODAL_TITLE', { storeName }),
  path: '/backoffice/stores/stores/create/details',
  isVisible: () => false,
};

export const BACKOFFICE_CASHIER_STORE_DETAILS = {
  getText: () => i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_STORE'),
  path: '/backoffice/stores/cashier-stores/:id/details',
  isVisible: () => false,
};
