import i18next from 'i18next';

/** ORDER */
export const getChoicesForAvailabilityDropdown = () => [
  {
    id: 1,
    name: i18next.t('ADMIN.STORES.ORDER_AVAILABLE'),
    itemValue: true,
  },
  {
    id: 2,
    name: i18next.t('ADMIN.STORES.ORDER_NOT_AVAILABLE'),
    itemValue: false,
  },
];

/** STOCK */
export const getChoicesForStockDropdown = () => [
  {
    id: 1,
    name: i18next.t('ADMIN.STORES.IN_STOCK'),
    itemValue: true,
  },
  {
    id: 2,
    name: i18next.t('ADMIN.STORES.NOT_IN_STOCK'),
    itemValue: false,
  },
];

/** LOSSES */
export const getChoicesForDlcLossDropdown = () => [
  {
    id: 1,
    name: i18next.t('ADMIN.STORES.WITH_LOSSES'),
    itemValue: true,
  },
  {
    id: 2,
    name: i18next.t('ADMIN.STORES.WITHOUT_LOSSES'),
    itemValue: false,
  },
];
