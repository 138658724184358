import { getActionAuthorization } from '../commons/utils';

const STORAGE_AREA_ACTIONS_RESOURCE = {
  CLONE: 'storage-area:clone',
  CREATE: 'storage-area:create',
  DELETE: 'storage-area:delete',
  EDIT: 'storage-area:edit',
  VIEW: 'storage-area:view',
};

export const canCloneStorageAreaParams = (actions) =>
  getActionAuthorization(actions, STORAGE_AREA_ACTIONS_RESOURCE.CLONE);

export const canCreateStorageArea = (actions) =>
  getActionAuthorization(actions, STORAGE_AREA_ACTIONS_RESOURCE.CREATE);

export const canDeleteStorageArea = (actions) =>
  getActionAuthorization(actions, STORAGE_AREA_ACTIONS_RESOURCE.DELETE);

export const canEditStorageArea = (actions) =>
  getActionAuthorization(actions, STORAGE_AREA_ACTIONS_RESOURCE.EDIT);

export const canViewStorageArea = (actions) =>
  getActionAuthorization(actions, STORAGE_AREA_ACTIONS_RESOURCE.VIEW);
