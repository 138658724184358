import { connect } from 'react-redux';
import { difference, get, keyBy, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

/** Actions */
import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

/** Commons */
import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import EmptyState from '@commons/EmptyState';
import utilsXLS from '@commons/utils/makeXLS';

import { getAssociateSupplierProductsModalConfig } from '@admin/inventories/utils/associateSupplierProductsModal/getAssociateSupplierProductsModalConfig.js';

/** Components */
import { SelectAvailabilitiesStepContent } from '@admin/suppliers/supplierProducts/utils/getSspmStepModalConfig';
import DeepsightFiltersButton from '@admin/components/FilterButton';

/** Hooks */
import { ENUM_QUERY_PARAMS, withListViewQueryParamHookHOC } from '@hooks/useListViewQueryParams';

/** Selectors */
import { canCreateSupplierProductMapping } from '@selectors/actions/storeActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

/** Services */
import { storeSupplierProductMapping as sspmService } from '@services/storeSupplierProductMapping';
import { supplier as supplierService } from '@services/supplier';
import storageAreaService from '@services/storageArea';
import storeService from '@services/store';

/** Utils */
import {
  getChoicesForAvailabilityDropdown,
  getChoicesForDlcLossDropdown,
  getChoicesForStockDropdown,
} from './utils/constants';
import { getListActions } from './utils/actions';
import { getListColumns } from './utils/columns';
import modalsUtils from './utils/modals';

const StoreSupplierProductMapping = (props) => {
  const {
    storeParams,
    listViewQueryParams,
    setListViewQueryParams,
    // Redux states
    client,
    currency,
    user,
    authorizedActions,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
  } = props;

  const { id: storeId, name: storeName } = storeParams;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  /** LIST */
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [columns] = useState(getListColumns());
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  /** SSPM DATA */
  const [storeSupplierProductMappings, setStoreSupplierProductMappings] = useState([]);
  const [selectedSSPMs, setSelectedSSPMs] = useState([]);
  const [filteredSupplierProducts, setFilteredSupplierProducts] = useState([]);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState({
    available: true,
    hasStock: true,
    hasDlc: true,
  });
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const [storageAreaKeyById, setStorageAreaKeyById] = useState([]);

  /** FILTERS */
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [suppliers, setSuppliers] = useState([]);

  // Order availability
  const [choicesForAvailabilityDropdown] = useState(getChoicesForAvailabilityDropdown());
  const [selectedAvailabilityDropdownItems, setSelectedAvailabilityDropdownItems] = useState(
    getChoicesForAvailabilityDropdown(),
  );
  // Used in stock
  const [choicesForStockDropdown] = useState(getChoicesForStockDropdown());
  const [selectedStockDropdownItems, setSelectedStockDropdownItems] = useState(
    getChoicesForStockDropdown(),
  );
  // Losses
  const [choicesForDlcLossDropdown] = useState(getChoicesForDlcLossDropdown());
  const [selectedDlcLossDropdownItems, setSelectedDlcLossDropdownItems] = useState(
    getChoicesForDlcLossDropdown(),
  );

  // Association modal
  const [availableSPForAssociation, setAvailableSPForAssociation] = useState([]);
  const [spToBeAdded, setSpToBeAdded] = useState([]);

  // Action SA modals states
  const [storageAreasToMap, setStorageAreasToMap] = useState([]);
  const [dissociating, setDissociating] = useState(false);

  /** USE EFFECTS */

  useEffect(() => {
    (async () => {
      const suppliersOfClient = await getSuppliers();
      await refreshData(suppliersOfClient);
    })();
  }, [storeId]);

  useEffect(() => {
    updateColumnsFilterList(storeSupplierProductMappings);

    if (!isLoading) {
      setDisplayEmptyState(!storeSupplierProductMappings.length);
    }
  }, [isLoading, storeSupplierProductMappings]);

  useEffect(() => {
    if (!storageAreasToMap.length) {
      return;
    }

    const params = getStorageAreasManagementModalParams(dissociating);

    refreshGenericModal(params);
  }, [storageAreasToMap]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const filteredSspms = storeSupplierProductMappings.filter(({ available, hasStock, hasDlc }) => {
      const orderAvailable = selectedAvailabilityDropdownItems.some(
        ({ itemValue }) => itemValue === available,
      );

      const stockAvailable = selectedStockDropdownItems.some(
        ({ itemValue }) => itemValue === hasStock,
      );

      const dlcLossAvailable = selectedDlcLossDropdownItems.some(
        ({ itemValue }) => itemValue === hasDlc,
      );

      return orderAvailable && stockAvailable && dlcLossAvailable;
    });

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredSupplierProducts(filteredSspms);
      return;
    }

    const filteredSspmsWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredSspms,
    );

    setFilteredSupplierProducts(filteredSspmsWithAdvancedFilters);
  }, [storeSupplierProductMappings, applyFilters, advancedFilters]);

  useEffect(() => {
    setActions(
      getListActions({
        selectedSupplierProducts: selectedSSPMs,
        filteredSupplierProducts,
        suppliers,
        user,
        client,
        handleAssociate,
        handleDeletion,
        handleExport,
        openSspmManagementModal,
        openStorageAreasManagementModal,
        authorizedActions,
      }),
    );
  }, [filteredSupplierProducts, selectedSSPMs, availableSPForAssociation]);

  useEffect(() => {
    const params = getSspmModalParams();

    refreshGenericModal(params);
  }, [selectedAvailabilities]);

  useEffect(() => {
    const params = getAssociateSupplierProductsModalConfig({
      hasLocalCatalogs: client.hasLocalCatalogs,
      setSpToBeAdded,
      availableSPForAssociation,
      handleAddAssociation,
      title: i18next.t('ADMIN.STORES.MAPPINGS_ASSOCIATION_MODAL_TITLE', { storeName }),
    });

    refreshGenericModal(params);
  }, [spToBeAdded, availableSPForAssociation]);

  /** METHODS */
  const getSuppliers = async () => {
    let suppliersOfClient = [];

    try {
      suppliersOfClient = await supplierService.getSuppliersOfClient(client.clientId);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
    } finally {
      setSuppliers(suppliersOfClient);

      return suppliersOfClient;
    }
  };

  const refreshData = async (suppliersOfClient) => {
    setIsLoading(true);

    await getStoreSupplierProductMappings(suppliersOfClient);
    await getSPToAssociate();

    setIsLoading(false);
  };

  const getStoreSupplierProductMappings = async (suppliersOfClient) => {
    if (!storeId || !suppliersOfClient.length) {
      return;
    }

    const suppliersKeyById = keyBy(suppliersOfClient, 'id');

    try {
      const startDate = moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const sspmsOfStore = await sspmService.getStoreSupplierProductMappingsOfStore(
        storeId,
        startDate,
        true, // filter by user catalog
      );

      const formattedSspm = sspmsOfStore.map((sspm) => {
        const { lnkSupplierproductStoresupplierproductmappingrel } = sspm;
        const {
          id: supplierProductId,
          supplierId,
          name,
          category,
          subCategory,
          price,
        } = lnkSupplierproductStoresupplierproductmappingrel;

        return {
          ...sspm,
          supplierProductId,
          supplierId,
          supplierName: get(suppliersKeyById, [supplierId, 'name'], ''),
          name,
          price,
          category: category || i18next.t('GENERAL.NONE_VALUE'),
          subCategory: subCategory || i18next.t('GENERAL.NONE_VALUE'),
        };
      });

      // Get storage areas of store
      const sspmWithSADetails = await getStorageAreasOfStore(formattedSspm);

      setStoreSupplierProductMappings(sortBy(sspmWithSADetails, 'name'));
    } catch {
      setStoreSupplierProductMappings([]);
      showErrorMessage(i18next.t('GENERAL.FETCH_SUPPLIER_PRODUCTS_FAILURE'));
    }
  };

  const getSPToAssociate = async () => {
    try {
      const supplierProducts = await storeService.getNotAssociatedSupplierProducts(storeId);

      setAvailableSPForAssociation(supplierProducts);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    }
  };

  const getStorageAreasOfStore = async (formattedSspm) => {
    try {
      const storageAreas = await storageAreaService.getStorageAreasByClientId(client.clientId);

      const storageAreasOfStore = storageAreas.filter(
        (storageArea) => storageArea.storeId === storeId,
      );

      setStorageAreaKeyById(keyBy(storageAreasOfStore, 'id'));

      const saIds = storageAreasOfStore.map(({ id }) => id);

      const associatedStorageAreaIdsGroupBySPIds = storageAreasOfStore.reduce(
        (acc, storageArea) => {
          // For each storage area, storageArea.supplierProductIds contains already associated supplier product ids
          storageArea.supplierProductIds.forEach((supplierProductId) => {
            if (!acc[supplierProductId]) {
              acc[supplierProductId] = [];
            }

            acc[supplierProductId].push(storageArea.id);
          });

          return acc;
        },
        {},
      );

      const formattedSspmWithStorageAreas = formattedSspm.map((item) => {
        const associatedStorageAreaIds = get(
          associatedStorageAreaIdsGroupBySPIds,
          [item.supplierProductId],
          null,
        );

        if (associatedStorageAreaIds) {
          return {
            ...item,
            associatedStorageAreaIds: associatedStorageAreaIdsGroupBySPIds[item.supplierProductId],
            associatedStorageAreaIdsCount: associatedStorageAreaIds.length,
            notAssociatedStorageAreaIds: difference(saIds, associatedStorageAreaIds),
          };
        }

        return {
          ...item,
          associatedStorageAreaIds: [],
          associatedStorageAreaIdsCount: 0,
          notAssociatedStorageAreaIds: saIds,
        };
      });

      return formattedSspmWithStorageAreas;
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.FETCHING_ERROR'));
    }
  };

  const updateColumnsFilterList = (storeSupplierProductMappings) => {
    const columnsList = columns.filter(({ filterType }) => !!filterType);

    const updateColumnsFilterList = columnsList.reduce((result, column) => {
      const updatedColumn = { ...column };

      const { propertyKey } = column;

      if (['category', 'subCategory', 'supplierName'].includes(propertyKey)) {
        const list = Object.values(
          storeSupplierProductMappings.reduce((result, sspm, key) => {
            if (sspm[propertyKey]) {
              result[sspm[propertyKey]] = {
                id: key,
                name: sspm[propertyKey],
                status: sspm[propertyKey],
                value: sspm[propertyKey],
                [propertyKey]: sspm[propertyKey],
              };
            }

            return result;
          }, {}),
        );

        updatedColumn.list = sortBy(list, 'name');
      }

      result.push(updatedColumn);

      return result;
    }, []);

    setColumnsFilterList(updateColumnsFilterList);
  };

  const openSspmManagementModal = () => {
    setSelectedSSPMs(selectedSSPMs);

    const params = getSspmModalParams();

    openGenericModal(params);
  };

  const getSspmModalParams = () => ({
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        handleClick: handleSspmUpdate,
        preventClosing: true,
      },
    ],
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_UPDATE_AVAILABILITIES'),
    icon: '/images/inpulse/pen-black-small.svg',
    type: 'action',
    handleCloseCleanUp: () => {
      setSelectedAvailabilities({
        available: true,
        hasStock: true,
        hasDlc: true,
      });
    },
    component: SelectAvailabilitiesStepContent,
    data: {
      selectedAvailabilities,
      setSelectedAvailabilities,
    },
    height: 'auto',
  });

  const handleSspmUpdate = async () => {
    setIsLoading(true);

    try {
      const sspmIds = selectedSSPMs.map(({ id }) => id);

      await sspmService.patchStoreSupplierProductMappingByIds(sspmIds, selectedAvailabilities);

      setSelectedAvailabilities({
        available: true,
        hasStock: true,
        hasDlc: true,
      });
      closeGenericModal();

      showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.AVAILABILITIES_SUCCESSFULLY_UPDATED'));

      await getStoreSupplierProductMappings(suppliers);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_SSPM_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const getAvailableStorageAreas = (isDissociation) => {
    const keyToUse = isDissociation ? 'associatedStorageAreaIds' : 'notAssociatedStorageAreaIds';

    const storageAreaIds = selectedSSPMs.reduce(
      (acc, { [keyToUse]: storageAreaIds }) => [...acc, ...storageAreaIds],
      [],
    );

    const uniqueStorageAreaIds = new Set(storageAreaIds);

    return Array.from(uniqueStorageAreaIds).map((id) => storageAreaKeyById[id]);
  };

  const getStorageAreasManagementModalParams = (isDissociation) => {
    const availableStorageAreas = getAvailableStorageAreas(isDissociation);

    const params = modalsUtils.getStorageAreaToMapParamModalConfig({
      handleSave: isDissociation ? handleSADissociation : handleSAAssociation,
      storageAreasToMap,
      setStorageAreasToMap,
      availableStorageAreas,
      isDissociation,
    });

    return params;
  };

  const openStorageAreasManagementModal = ({ isDissociation = false }) => {
    setDissociating(isDissociation);

    const params = getStorageAreasManagementModalParams(isDissociation);

    openGenericModal(params);
  };

  const handleSAAssociation = async () => {
    setIsLoading(true);

    try {
      await storageAreaService.addStorageAreaMappings(
        storageAreasToMap.map(({ id }) => id),
        selectedSSPMs.map(({ supplierProductId }) => supplierProductId),
      );

      setStorageAreasToMap([]);

      showSuccessMessage(i18next.t('ADMIN.STORES.SSPM_ACTION_ADD_TO_SA_SUCCESS'));

      await getStoreSupplierProductMappings(suppliers);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.SSPM_ACTION_ADD_TO_SA_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSADissociation = async () => {
    setIsLoading(true);

    try {
      await storageAreaService.deleteStorageAreaMappings(
        storageAreasToMap.map(({ id }) => id),
        selectedSSPMs.map(({ supplierProductId }) => supplierProductId),
      );

      setStorageAreasToMap([]);

      showSuccessMessage(i18next.t('ADMIN.STORES.SSPM_ACTION_REMOVE_FROM_SA_SUCCESS'));

      await getStoreSupplierProductMappings(suppliers);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.SSPM_ACTION_REMOVE_FROM_SA_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAssociation = async () => {
    setIsLoading(true);

    try {
      const mappings = spToBeAdded.map((supplierProduct) => ({
        ...supplierProduct,
        storeId,
        supplierProductId: supplierProduct.id,
        hasStock: true,
        available: true,
        hasDlc: true,
      }));

      await sspmService.createMultipleStoreSupplierProductMapping(mappings);

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_STORE_MAPPING_SUCCESS', {
          count: mappings.length,
        }),
      );

      refreshData(suppliers);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_STORE_MAPPING_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssociate = () => {
    const params = getAssociateSupplierProductsModalConfig({
      hasLocalCatalogs: client.hasLocalCatalogs,
      availableSPForAssociation,
      handleAddAssociation,
      setSpToBeAdded,
      title: i18next.t('ADMIN.STORES.MAPPINGS_ASSOCIATION_MODAL_TITLE', { storeName }),
    });

    openGenericModal(params);
  };

  const handleDeletion = async () => {
    setIsLoading(true);

    const sspmIds = selectedSSPMs.map(({ id }) => id);

    try {
      await sspmService.deleteMultipleStoreSupplierProductMapping(sspmIds);
      getStoreSupplierProductMappings(suppliers);

      await refreshData(suppliers);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.DELETE_ASSOCIATION_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = (dataToExport) => {
    const sheetName = `${i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_ENTITIES')}_${storeName}`;

    const formattedExportColumns = columns.map((column) => {
      if (['available', 'hasStock', 'hasDlc'].includes(column.propertyKey)) {
        return {
          ...column,
          transform: (item) => i18next.t(item ? 'GENERAL.YES' : 'GENERAL.NO'),
        };
      }

      return column;
    });

    const sheet = utilsXLS.generateDefaultSheet(
      sheetName,
      formattedExportColumns,
      dataToExport,
      currency,
    );

    utilsXLS.makeXLS(i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_ENTITIES'), [sheet]);
  };

  const renderEmptyState = () => (
    <EmptyState
      label={i18next.t('ADMIN.STORES.SSPM_EMPTY_STATE_TITLE')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() =>
        canCreateSupplierProductMapping(authorizedActions) && (
          <Button
            handleClick={handleAssociate}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ASSOCIATE')}
          />
        )
      }
      subtitle={i18next.t('ADMIN.STORES.SSPM_EMPTY_STATE_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  if (displayEmptyState) {
    return renderEmptyState();
  }

  return (
    <ListView
      actions={actions}
      columns={columns}
      data={filteredSupplierProducts}
      defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
      defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
      defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
      defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
      defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
      handleCurrentPageChange={(input) =>
        setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
      }
      handleMaxPerPageChange={(input) =>
        setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
      }
      handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
      handleOrderTypeChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)}
      handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
      isLoading={isLoading}
      languageCode={userLanguageCode}
      padding={'108px 24px 0px 24px'}
      placeholderShape={i18next.t('GENERAL.SEARCH')}
      renderEmptyState={() => <EmptyState />}
      renderFilterButton={() => (
        <DeepsightFiltersButton
          advancedFilters={advancedFilters}
          applyFilters={applyFilters}
          columnsFilterList={columnsFilterList}
          customMultipleDropDowns={[
            {
              id: 'availablility-supplier-products',
              icon: '/images/icon-dropdown-grey.svg',
              list: choicesForAvailabilityDropdown,
              defaultSelectedItems: choicesForAvailabilityDropdown,
              selectedItems: selectedAvailabilityDropdownItems,
              setSelectedItems: setSelectedAvailabilityDropdownItems,
            },
            {
              id: 'stock-supplier-products',
              icon: '/images/icon-dropdown-grey.svg',
              list: choicesForStockDropdown,
              defaultSelectedItems: choicesForStockDropdown,
              selectedItems: selectedStockDropdownItems,
              setSelectedItems: setSelectedStockDropdownItems,
            },
            {
              id: 'dlc-loss-supplier-products',
              icon: '/images/icon-dropdown-grey.svg',
              list: choicesForDlcLossDropdown,
              defaultSelectedItems: choicesForDlcLossDropdown,
              selectedItems: selectedDlcLossDropdownItems,
              setSelectedItems: setSelectedDlcLossDropdownItems,
            },
          ]}
          filters={filters}
          isLoading={isLoading}
          minWidth={120}
          readOnly={isLoading}
          setAdvancedFilters={setAdvancedFilters}
          setApplyFilters={setApplyFilters}
          setFilters={setFilters}
          textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
        />
      )}
      setSelectedItems={setSelectedSSPMs}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/stores/:id/supplier-products',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withListViewQueryParamHookHOC(StoreSupplierProductMapping));
