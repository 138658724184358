import { head } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import {
  getAssignCategoriesModalParams,
  getManageCategoriesModalParams,
  getManageSubCategoriesModalParams,
  getAssignSubCategoriesModalParams,
} from '@commons/constants/categoryTypes';
import { isUserDeepsight } from '@commons/utils/roles';
import sortActionsList from '@commons/utils/sortActionsList';

import {
  canCreateSupplierProducts,
  canDownloadSupplierProductTemplate,
  canEditSupplierProducts,
  canImportSupplierProduct,
  canMapSupplierProductAutomatically,
} from '@selectors/actions/supplierProductActions';
import { canSeeAllCatalogs, canUpdateAssociatedSupplier } from '@selectors/user';

import { IMPORT_TYPES } from './constants';

const ROW_ACTIONS_ONLY_FOR_ADMIN_CC = [
  'assign-category',
  'assign-sub-category',
  'assign-inventory-template',
  'deactivate-action',
  'delete-scheduled-next-availabilities-action',
  'delete-scheduled-next-price-action',
  'activate-action',
];

const computeRightToRunAction = (
  actionId,
  {
    supplierId,
    isKitchen = false,
    centralKitchenSuppliersSSPMsOfUser = [],
    authorizedActions = null,
    suppliersKeyById = {},
    hasLocalCatalogs,
    user,
  },
) => {
  let canEdit = true;

  if (authorizedActions) {
    canEdit = canEditSupplierProducts(authorizedActions);
  }

  const canUpdateAssociatedSupplierData = canUpdateAssociatedSupplier(
    user,
    suppliersKeyById[supplierId],
    {
      hasLocalCatalogs,
    },
  );

  let hasRightToRunThisAction = true;

  if (ROW_ACTIONS_ONLY_FOR_ADMIN_CC.includes(actionId)) {
    hasRightToRunThisAction = !isKitchen || centralKitchenSuppliersSSPMsOfUser.includes(supplierId);
  }

  return !canEdit || !hasRightToRunThisAction || !canUpdateAssociatedSupplierData;
};

const getRowActions = ({
  user,
  hasLocalCatalogs,
  suppliersKeyById,
  centralKitchenSuppliersSSPMsOfUser,
  filteringOnActive,
  openSspmManagementModal,
  handleDuplicateSupplierProduct,
  handleEnableSupplierProduct,
  handleDisableSupplierProducts,
  openSupplierProductsDeletionModal,
  openNextAvailabilitiesSchedulingModal,
  handleDeleteScheduledAvailabilitiesModal,
  handleDeleteScheduledPriceModal,
  openPriceEditableAtReceptionModal,
  authorizedActions,
  openCategoriesBatchUpdateModal,
  openSubCategoriesBatchUpdateModal,
}) =>
  sortActionsList([
    {
      id: 'assign-category',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
      handleAction: (item) =>
        openCategoriesBatchUpdateModal(true, [item], getManageCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/link-ip-black.svg',
      isHidden: ({ isKitchen, supplierId }) =>
        computeRightToRunAction('assign-category', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }) || !filteringOnActive,
    },
    {
      id: 'assign-sub-category',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_SUBCATEGORY'),
      handleAction: (item) =>
        openSubCategoriesBatchUpdateModal(true, [item], getManageSubCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/link-ip-black.svg',
      isHidden: ({ supplierId, isKitchen }) =>
        computeRightToRunAction('assign-sub-category', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }) || !filteringOnActive,
    },
    {
      id: 'define-availability-action',
      actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_AVAILABILITY'),
      handleAction: (selectedSupplierProduct) => openSspmManagementModal([selectedSupplierProduct]),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: ({ SSPMCount, supplierId, isKitchen }) =>
        !filteringOnActive ||
        SSPMCount == 0 ||
        computeRightToRunAction('define-availability-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }),
    },
    {
      id: 'define-price-editable-at-reception',
      actionLabel: () =>
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_PRICE_EDITABLE_AT_RECEPTION'),
      handleAction: (selectedSupplierProduct) =>
        openPriceEditableAtReceptionModal([selectedSupplierProduct]),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: ({ supplierId }) =>
        !filteringOnActive ||
        !canEditSupplierProducts(authorizedActions) ||
        !canUpdateAssociatedSupplier(user, suppliersKeyById[supplierId], { hasLocalCatalogs }),
    },
    {
      id: 'define-next-availabilities-action',
      actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_SCHEDULE_NEXT_AVAILABILITIES'),
      handleAction: (selectedSupplierProduct) =>
        openNextAvailabilitiesSchedulingModal([selectedSupplierProduct]),
      actionIcon: () => '/images/inpulse/calendar-black-small.svg',
      isHidden: ({ SSPMCount, supplierId, isKitchen }) =>
        !filteringOnActive ||
        SSPMCount == 0 ||
        computeRightToRunAction('define-next-availabilities-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }),
    },
    {
      id: 'duplicate-action',
      actionIcon: () => '/images/inpulse/duplicate-ip-black.svg',
      actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.DUPLICATE_SUPPLIER_PRODUCT'),
      handleAction: (supplierProduct) => handleDuplicateSupplierProduct(supplierProduct),
      isHidden: ({ isKitchen, supplierId }) =>
        !filteringOnActive ||
        isKitchen ||
        computeRightToRunAction('duplicate-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          user,
        }) ||
        !canCreateSupplierProducts(authorizedActions),
    },
    {
      id: 'deactivate-action',
      actionIcon: () => '/images/inpulse/power-ip-black.svg',
      actionLabel: () =>
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_SUPPLIER_PRODUCT', { count: 1 }),
      handleAction: (item) => handleDisableSupplierProducts([item]),
      isHidden: ({ supplierId, isKitchen }) =>
        !filteringOnActive ||
        computeRightToRunAction('deactivate-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }),
    },
    {
      id: 'delete-scheduled-next-availabilities-action',
      actionLabel: () =>
        i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_AVAILABILITY_SINGLE',
        ),
      handleAction: (selectedSupplierProduct) =>
        handleDeleteScheduledAvailabilitiesModal([selectedSupplierProduct]),
      actionIcon: () => '/images/inpulse/trash-black.svg',
      isHidden: ({ supplierId, isKitchen, nextAvailabilities }) =>
        !filteringOnActive ||
        !nextAvailabilities ||
        !nextAvailabilities.length ||
        computeRightToRunAction('delete-scheduled-next-availabilities-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }),
    },
    {
      id: 'delete-scheduled-next-price-action',
      actionLabel: () =>
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_PRICE_SINGLE'),
      handleAction: (selectedSupplierProduct) =>
        handleDeleteScheduledPriceModal([selectedSupplierProduct]),
      actionIcon: () => '/images/inpulse/trash-black.svg',
      isHidden: ({ scheduledPrice, supplierId, isKitchen }) =>
        !filteringOnActive ||
        !scheduledPrice ||
        computeRightToRunAction('delete-scheduled-next-price-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }),
    },
    {
      id: 'activate-action',
      actionIcon: () => '/images/inpulse/power-ip-black.svg',
      actionLabel: () =>
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTIVATE_SUPPLIER_PRODUCT', { count: 1 }),
      handleAction: (item) => handleEnableSupplierProduct(item),
      isHidden: ({ supplierId, isKitchen }) =>
        filteringOnActive ||
        computeRightToRunAction('activate-action', {
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          authorizedActions,
          user,
        }),
    },
    {
      id: 'csm-delete-action',
      actionIcon: () => '/images/inpulse/trash-white.svg',
      actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SUPPLIER_PRODUCT', { count: 1 }),
      handleAction: (item) => openSupplierProductsDeletionModal([item]),
      isHighlighted: () => true,
      isHidden: ({ isKitchen, supplierId }) =>
        !isUserDeepsight(user) ||
        filteringOnActive ||
        isKitchen ||
        computeRightToRunAction(
          'csm-delete-action',
          supplierId,
          isKitchen,
          centralKitchenSuppliersSSPMsOfUser,
          hasLocalCatalogs,
          suppliersKeyById,
          user,
        ),
    },
  ]);

const getGlobalActions = ({
  user,
  hasLocalCatalogs,
  suppliersKeyById,
  centralKitchenSuppliersSSPMsOfUser,
  filteringOnActive,
  selectedSupplierProducts,
  supplierProducts,
  handleNewSupplierProductCreation,
  handleDuplicateSupplierProduct,
  handleExportCategorySubCategory,
  handleEnableSupplierProduct,
  handleDisableSupplierProducts,
  openSupplierProductsDeletionModal,
  openGenericModalForFileImport,
  getExcelSupplierProductEditPriceVatRate,
  getSupplierProductsBatchCreationTemplate,
  openSspmManagementModal,
  openNextAvailabilitiesSchedulingModal,
  handleDeleteScheduledAvailabilitiesModal,
  handleDeleteScheduledPriceModal,
  authorizedActions,
  openPriceEditableAtReceptionModal,
  openMappingAutoModal,
  openCategoriesBatchUpdateModal,
  openSubCategoriesBatchUpdateModal,
}) => {
  const hasSelectedItems = !!selectedSupplierProducts.length;

  const isSingleSelection = selectedSupplierProducts.length === 1;

  const isAtLeastOneAssociatedToStore = selectedSupplierProducts.some(
    ({ SSPMCount }) => SSPMCount > 0,
  );

  const doesOneSPHasScheduledAvailabilities = selectedSupplierProducts.some(
    ({ nextAvailabilities }) => !!nextAvailabilities && !!nextAvailabilities.length,
  );

  const doesOneSPHasScheduledPrices = selectedSupplierProducts.some(
    ({ scheduledPrice }) => !!scheduledPrice,
  );

  const hasAtLeastOneKitchenSP = selectedSupplierProducts.some(({ isKitchen }) => isKitchen);

  // allows to know if there is at least one selected SP that is a central kitchen SP
  // where the admin is not linked to the CC store of this SP.
  // This lets us know which actions to display.

  const displayMinimalActions = selectedSupplierProducts.some(
    ({ isKitchen, supplierId }) =>
      isKitchen && !centralKitchenSuppliersSSPMsOfUser.includes(supplierId),
  );

  const userHasRightToUpdateAllSelectedItems = selectedSupplierProducts.every(({ supplierId }) =>
    canUpdateAssociatedSupplier(user, suppliersKeyById[supplierId], { hasLocalCatalogs }),
  );

  const actions = [
    {
      fixed: canCreateSupplierProducts(authorizedActions),
      id: 'action-create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      handleAction: () => handleNewSupplierProductCreation(),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={handleNewSupplierProductCreation}
          icon="/images/inpulse/add-inpulse-white-small.svg"
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
      isHidden: () => !canCreateSupplierProducts(authorizedActions),
    },
    {
      id: 'csm-action-download-creation-file-template',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DOWNLOAD_TEMPLATE_FOR_IMPORT'),
      handleAction: getSupplierProductsBatchCreationTemplate,
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      disabledTooltipText: () => i18next.t('GENERAL.ONLY_ADMIN_RIGHT'),
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        !canDownloadSupplierProductTemplate(authorizedActions),
    },
    {
      id: 'csm-action-import-supplier-products-creation-file',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.IMPORT_TEMPLATE'),
      handleAction: () => openGenericModalForFileImport(IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT),
      actionIcon: () => '/images/inpulse/file-upload-black-small.svg',
      disabledTooltipText: () => i18next.t('GENERAL.ONLY_ADMIN_RIGHT'),
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        displayMinimalActions ||
        !canImportSupplierProduct(authorizedActions),
    },
    {
      id: 'action-update-category',
      actionLabel: i18next.t('GENERAL.UPDATE_CATEGORIES'),
      handleAction: () =>
        openCategoriesBatchUpdateModal(false, [], getManageCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        displayMinimalActions ||
        !canEditSupplierProducts(authorizedActions) ||
        (hasLocalCatalogs && !canSeeAllCatalogs(user, { hasLocalCatalogs })),
    },
    {
      id: 'action-update-sub-category',
      actionLabel: i18next.t('GENERAL.UPDATE_SUB_CATEGORIES'),
      handleAction: () =>
        openSubCategoriesBatchUpdateModal(false, [], getManageSubCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        displayMinimalActions ||
        !canEditSupplierProducts(authorizedActions) ||
        (hasLocalCatalogs && !canSeeAllCatalogs(user, { hasLocalCatalogs })),
    },
    {
      id: 'export',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.EXPORT_SELECTION', {
        count: selectedSupplierProducts.length,
      }),
      handleAction: () => handleExportCategorySubCategory(!hasSelectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () => supplierProducts.length <= 0,
    },
    {
      id: 'export-action-edit-price',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_EDIT_PRICE_XLS'),
      handleAction: async () => getExcelSupplierProductEditPriceVatRate(),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'export-action-import-price-supplier-products',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_IMPORT_SUPPLIER_PRODUCTS_XLS'),
      handleAction: async () => openGenericModalForFileImport(IMPORT_TYPES.PRICE_IMPORT),
      // actionIcon: () => '/images/inpulse/file-upload-black-small.svg',
      actionIcon: () => '/images/inpulse/file-upload-lmgrey-small.svg', // TEMP FIX
      isDisabled: () => true, // TEMP FIX
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'assign-category',
      actionIcon: () => '/images/inpulse/link-ip-black.svg',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
      handleAction: (selectedItems) =>
        openCategoriesBatchUpdateModal(true, selectedItems, getAssignCategoriesModalParams()),
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        displayMinimalActions ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'assign-sub-category',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_SUBCATEGORY'),
      handleAction: (selectedItems) =>
        openSubCategoriesBatchUpdateModal(true, selectedItems, getAssignSubCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/link-ip-black.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        displayMinimalActions ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'activate-action',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTIVATE_SUPPLIER_PRODUCT', {
        count: selectedSupplierProducts.length,
      }),
      handleAction: () => handleEnableSupplierProduct(head(selectedSupplierProducts)),
      actionIcon: () => '/images/inpulse/power-ip-black.svg',
      isHidden: () =>
        !isSingleSelection ||
        filteringOnActive ||
        displayMinimalActions ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'duplicate-action',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DUPLICATE_SUPPLIER_PRODUCT'),
      actionIcon: () => '/images/inpulse/duplicate-ip-black.svg',
      handleAction: () => handleDuplicateSupplierProduct(selectedSupplierProducts[0]),
      isHidden: () =>
        !filteringOnActive ||
        !isSingleSelection ||
        hasAtLeastOneKitchenSP ||
        !canCreateSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'define-availability-action',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_AVAILABILITY'),
      handleAction: () => openSspmManagementModal(selectedSupplierProducts),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        !isAtLeastOneAssociatedToStore ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'define-price-editable-at-reception',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_PRICE_EDITABLE_AT_RECEPTION'),
      handleAction: () => openPriceEditableAtReceptionModal(selectedSupplierProducts),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'define-next-availabilities-action',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_SCHEDULE_NEXT_AVAILABILITIES'),
      handleAction: () => openNextAvailabilitiesSchedulingModal(selectedSupplierProducts),
      actionIcon: () => '/images/inpulse/calendar-black-small.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        !isAtLeastOneAssociatedToStore ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'deactivate-action',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_SUPPLIER_PRODUCT', {
        count: selectedSupplierProducts.length,
      }),
      handleAction: () => handleDisableSupplierProducts(selectedSupplierProducts),
      actionIcon: () => '/images/inpulse/power-ip-black.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        displayMinimalActions ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'delete-scheduled-next-availabilities-action',
      actionLabel: isSingleSelection
        ? i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_AVAILABILITY_SINGLE',
          )
        : i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_AVAILABILITY_MULTIPLE',
          ),
      handleAction: () => handleDeleteScheduledAvailabilitiesModal(selectedSupplierProducts),
      actionIcon: () => '/images/inpulse/trash-black.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        !doesOneSPHasScheduledAvailabilities ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'delete-scheduled-next-price-action',
      actionLabel: isSingleSelection
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_PRICE_SINGLE')
        : i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_PRICE_MULTIPLE',
          ),
      handleAction: (selectedSupplierProducts) =>
        handleDeleteScheduledPriceModal(selectedSupplierProducts),
      actionIcon: () => '/images/inpulse/trash-black.svg',
      isHidden: () =>
        !filteringOnActive ||
        !hasSelectedItems ||
        !doesOneSPHasScheduledPrices ||
        !canEditSupplierProducts(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'csm-delete-action',
      actionIcon: () => '/images/inpulse/trash-white.svg',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SUPPLIER_PRODUCT', {
        count: selectedSupplierProducts.length,
      }),
      handleAction: (items) => openSupplierProductsDeletionModal(items),
      isHighlighted: () => true,
      isHidden: () =>
        !isUserDeepsight(user) ||
        filteringOnActive ||
        !hasSelectedItems ||
        hasAtLeastOneKitchenSP ||
        !userHasRightToUpdateAllSelectedItems,
    },
    {
      id: 'csm-mapping-ingredient-auto',
      actionIcon: () => '/images/inpulse/lightning-black-small.svg',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_INGREDIENTS'),
      handleAction: openMappingAutoModal,
      isHidden: () =>
        !filteringOnActive ||
        hasSelectedItems ||
        !canMapSupplierProductAutomatically(authorizedActions) ||
        !userHasRightToUpdateAllSelectedItems,
    },
  ];

  return sortActionsList(actions);
};

export default { getRowActions, getGlobalActions };
