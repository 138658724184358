import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_ADD_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import AssociateSupplierProductsModal from './index';

export const getAssociateSupplierProductsModalConfig = ({
  hasLocalCatalogs,
  setSpToBeAdded,
  availableSPForAssociation,
  handleAddAssociation,
  title,
}) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title,
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 0px 0px 0px',
  component: AssociateSupplierProductsModal,
  data: {
    hasLocalCatalogs,
    setSpToBeAdded,
    availableSPForAssociation,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_ADD_BUTTON(),
      handleClick: handleAddAssociation,
    },
  ],
});
