import { connect } from 'react-redux';
import { get } from 'lodash';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { closeGenericModal } from '@actions/modal';
import { loading, loadingFailure, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { buildArraySchema } from '@commons/GenericForm';
import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { isUserManager } from '@commons/utils/roles';
import GenericModal from '@commons/Modals/GenericModal';
import NavBar from '@commons/NavigationBar';

import { getClientInfo } from '@selectors/client';

/** Services **/
import { brand as brandService } from '@services/brand';
import { ingredient as ingredientService } from '@services/ingredient';
import centralService from '@services/central';
import channelService from '@services/channel';
import clientService from '@services/client';
import recipeService from '@services/recipe';

import { getIsInCentralKitchenOnlyPage } from '@admin/utils/centralKitchen';

import {
  Container,
  ContainerContent,
  GenericModalContainer,
  NavBarContainer,
  NavigationBar,
  NavigationPage,
  Section,
} from './styledComponents';
import { fetchRecipeParents, fetchRecipesByCategories } from './common/services';
import { formatCompositions, formatEntity } from './components/RecipeComposition/utils/format';
import { getTableFormColumns } from './components/RecipeComposition/utils/getColumns';
import { RECIPES_COMPONENTS_FIELD_ARRAY_NAME } from './components/RecipeComposition/utils/formInputsConfiguration';
import { sortCompositions } from './utils/sortCompositions';
import BottomBar from './components/BottomBar';
import RecipeComposition from './components/RecipeComposition';
import RecipeInfoSection from './components/RecipeInfo';
import RecipeParentAssociations from './components/RecipeParentAssociations';
import RecipePictureSection from './components/RecipePictureSection';
import RecipePreparationSteps from './components/RecipePreparationSteps';
import RecipeProduction from './components/RecipeProduction';
import utilsMethods from './utils';

const RECIPE_ID_CREATE_MODE = 'create';
const RECIPE_ID_DUPLICATE_MODE = 'duplicate';
const EMPTY_RECIPE = {
  id: null,
  name: '',
  category: '',
  unit: '',
  supplierProducts: [],
  allergens: [],
  hasMultipleChannels: false,
  hasStock: false,
  batchProduction: false,
  hasLoss: false,
  recipePreparationSteps: null,
  value: null,
  brands: [],
  inventoryListTemplates: [],
  categoryId: null,
};

const RECIPE_COMPOSITION_SUB_PAGE = {
  name: ({ compositions }) =>
    i18next.t('ADMIN.RECIPES.COMPONENT_WITH_COUNT', { count: compositions.length }),
  render: (props) => {
    const {
      allergens,
      clientId,
      handleCallToCreateIngredient,
      isReadOnly,
      recipe,
      recipesAvailable,
      setWarningModalParams,
      setIsSaveDisabled,
      compositions,
      channels,
      associatedProduct,
      isDuplicatingRecipe,
      tableColumns,
      recipeComponentsFormInputs,
      recipeComponentsForm,
      formFields,
      addEntity,
      deleteEntity,
      fields,
      replace,
      isKitchen,
    } = props;
    return (
      <RecipeComposition
        addEntity={addEntity}
        allergens={allergens}
        associatedProduct={associatedProduct}
        channels={channels}
        clientId={clientId}
        compositions={compositions}
        deleteEntity={deleteEntity}
        fields={fields}
        formFields={formFields}
        handleCallToCreateIngredient={handleCallToCreateIngredient}
        isDuplicatingRecipe={isDuplicatingRecipe}
        isKitchen={isKitchen}
        isReadOnly={isReadOnly}
        recipe={recipe}
        recipeComponentsForm={recipeComponentsForm}
        recipeComponentsFormInputs={recipeComponentsFormInputs}
        recipesAvailable={recipesAvailable}
        replace={replace}
        setIsSaveDisabled={setIsSaveDisabled}
        setWarningModalParams={setWarningModalParams}
        tableColumns={tableColumns}
      />
    );
  },
};

const RECIPE_PRODUCTION_SUB_PAGE = {
  name: ({ mappedStoreEntityMappings }) =>
    i18next.t('ADMIN.RECIPES.PRODUCTION_WITH_COUNT', { count: mappedStoreEntityMappings.length }),
  render: (props) => {
    const {
      recipe,
      setWarningModalParams,
      associatedProduct,
      mappedStoreEntityMappings,
      setMappedStoreEntityMappings,
      notMappedStoreEntityMappings,
      setNotMappedStoreEntityMappings,
      selectedStoreEntityMappings,
      setSelectedStoreEntityMappings,
    } = props;

    return (
      <RecipeProduction
        associatedProduct={associatedProduct}
        mappedStoreEntityMappings={mappedStoreEntityMappings}
        notMappedStoreEntityMappings={notMappedStoreEntityMappings}
        recipe={recipe}
        selectedStoreEntityMappings={selectedStoreEntityMappings}
        setMappedStoreEntityMappings={setMappedStoreEntityMappings}
        setNotMappedStoreEntityMappings={setNotMappedStoreEntityMappings}
        setSelectedStoreEntityMappings={setSelectedStoreEntityMappings}
        setWarningModalParams={setWarningModalParams}
      />
    );
  },
};

const RECIPE_PREPARATION_STEPS_SUB_PAGE = {
  name: () => i18next.t('ADMIN.RECIPES.PREPARATION_STEPS'),
  render: (props) => {
    const { recipe, compositions, allergens, onRecipeChange } = props;

    return (
      <RecipePreparationSteps
        allergens={allergens}
        compositions={compositions}
        recipe={recipe}
        onRecipeChange={onRecipeChange}
      />
    );
  },
};

const RECIPE_PARENTS_SUB_PAGE = {
  name: ({ entitiesMapped }) =>
    `${i18next.t('ADMIN.RECIPES.ELEMENTS_USING_THIS_RECIPE')} (${entitiesMapped.length})`,
  render: (props) => (
    <RecipeParentAssociations
      entitiesMapped={props.entitiesMapped}
      recipe={props.recipe}
      showMessage={props.showMessage}
      onEntitiesMappedChange={props.onEntitiesMappedChange}
    />
  ),
};

export function renderSectionRecipeSubPages(props) {
  const { activeSubPages, entitiesMapped, compositions, mappedStoreEntityMappings } = props;

  return (
    <>
      {activeSubPages.length > 1 && (
        <Section>
          <NavigationBar>
            {activeSubPages.map((page, indexPage) => (
              <NavigationPage
                key={indexPage}
                selected={props.selectedRecipeSubPage.name === page.name}
                onClick={() => props.onSelectedRecipeSubPage(page)}
              >
                {page.name({ compositions, entitiesMapped, mappedStoreEntityMappings })}
              </NavigationPage>
            ))}
          </NavigationBar>
        </Section>
      )}

      <Section nbSubPages={props.activeSubPages.length} sectionType="subpage">
        {props.selectedRecipeSubPage.render(props)}
      </Section>
    </>
  );
}

export function renderContent(props) {
  const path = get(props, 'match.path');

  const {
    recipe,
    history,
    isReadOnly,
    onRecipeChange,
    allergens,
    recipeCategories,
    recipeCost,
    recipeId,
    warningModalParams,
    setWarningModalParams,
    isSaveDisabled,
    compositions,
    recipeComponentsForm,
    client: { hasMultipleChannels, hasMultipleBrands },
    isKitchen,
    mappedStoreEntityMappings,
    initialMappedStoreEntityMappings,
    initialCompositions,
    initialRecipe,
    brands,
    inventoryListTemplates,
    setRecipeCategories,
    setInventoryListTemplates,
  } = props;

  return (
    <>
      <NavBarContainer>
        <NavBar
          bigTopBar={true}
          module={
            isKitchen ? ENUM_MODULE_NAME.KITCHEN_RECIPE_DETAIL : ENUM_MODULE_NAME.RECIPE_DETAIL
          }
          path={path}
          recipe={recipe}
        />
        {recipeComponentsForm && (
          <BottomBar
            clientHasMultipleBrands={hasMultipleBrands}
            clientHasMultipleChannels={hasMultipleChannels}
            compositions={compositions}
            history={history}
            initialCompositions={initialCompositions}
            initialProductionStoreEntityMappings={initialMappedStoreEntityMappings}
            initialRecipe={initialRecipe}
            isReadOnly={isReadOnly}
            isSaveDisabled={isSaveDisabled}
            productionStoreEntityMappings={mappedStoreEntityMappings}
            recipe={recipe}
            recipeComponentsForm={recipeComponentsForm}
            onRecipeChange={onRecipeChange}
          />
        )}
      </NavBarContainer>
      <ContainerContent>
        <Section>
          <RecipeInfoSection
            allergens={allergens}
            brands={brands}
            clientHasMultipleBrands={hasMultipleBrands}
            clientHasMultipleChannels={hasMultipleChannels}
            inventoryListTemplates={inventoryListTemplates}
            isKitchen={isKitchen}
            isReadOnly={isReadOnly}
            recipe={recipe}
            recipeCategories={recipeCategories}
            recipeCost={recipeCost}
            recipeId={recipeId}
            setInventoryListTemplates={setInventoryListTemplates}
            setRecipeCategories={setRecipeCategories}
            onRecipeChange={onRecipeChange}
          />
          <RecipePictureSection
            isReadOnly={isReadOnly}
            recipe={recipe}
            onRecipeChange={onRecipeChange}
          />
        </Section>
        {renderSectionRecipeSubPages(props)}
      </ContainerContent>
      {warningModalParams && (
        <GenericModalContainer>
          <GenericModal
            closeGenericModal={() => setWarningModalParams(null)}
            component={warningModalParams.component}
            params={warningModalParams}
          />
        </GenericModalContainer>
      )}
    </>
  );
}

export const clearState = (
  onEntitiesMappedChange,
  onActiveSubPageChange,
  onSelectedRecipeSubPage,
) => {
  onEntitiesMappedChange(false);
  onActiveSubPageChange([RECIPE_COMPOSITION_SUB_PAGE, RECIPE_PREPARATION_STEPS_SUB_PAGE]);
  onSelectedRecipeSubPage(RECIPE_COMPOSITION_SUB_PAGE);
};

export const RecipeDetails = (props) => {
  const {
    match: { params },
    client: { defaultChannelId, hasMultipleChannels, hasMultipleBrands },
    currency,
    location,
    user,
    pageLoaded,
    pageLoading,
    pageDidNotLoad,
    showErrorMessage,
    closeGenericModal,
    showSuccessMessage,
  } = props;

  const recipeId = params.id;
  const clientId = get(user, 'clientId');
  const isDuplicatingRecipe =
    location && location.type === RECIPE_ID_DUPLICATE_MODE && !!location.recipe;

  const [isDuplication, onDuplicationChange] = useState(false);

  const [recipe, onRecipeChange] = useState(EMPTY_RECIPE);
  const [initialRecipe, setInitialRecipe] = useState(EMPTY_RECIPE);
  const [initialCompositions, setInitialCompositions] = useState([]);

  const [recipeCost, onRecipeCostChange] = useState(null);
  const [compositions, setCompositions] = useState([]);
  const [allergens, onAllergensChange] = useState([]);

  const [recipesAvailable, onRecipesAvailableChange] = useState([]);
  const [recipeCategories, setRecipeCategories] = useState([]);

  const [brands, setBrands] = useState([]);
  const [inventoryListTemplates, setInventoryListTemplates] = useState([]);

  const [entitiesMapped, onEntitiesMappedChange] = useState(false);
  const [activeSubPages, onActiveSubPageChange] = useState([
    RECIPE_COMPOSITION_SUB_PAGE,
    RECIPE_PREPARATION_STEPS_SUB_PAGE,
  ]);
  const [selectedRecipeSubPage, onSelectedRecipeSubPage] = useState(RECIPE_COMPOSITION_SUB_PAGE);

  const [warningModalParams, setWarningModalParams] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [channels, setChannels] = useState([]);
  const [associatedProduct, setAssociatedProduct] = useState({});

  // Used by Central Kitchen Production sub-page
  const [mappedStoreEntityMappings, setMappedStoreEntityMappings] = useState([]);
  const [initialMappedStoreEntityMappings, setInitialMappedStoreEntityMappings] = useState([]);
  const [notMappedStoreEntityMappings, setNotMappedStoreEntityMappings] = useState([]);
  const [selectedStoreEntityMappings, setSelectedStoreEntityMappings] = useState([]);

  const isKitchen = getIsInCentralKitchenOnlyPage();

  const tableColumns = getTableFormColumns(defaultChannelId, currency, recipe);
  const movableColumns = tableColumns.filter(({ isFixed }) => !isFixed);

  const recipeComponentsFormInputs = movableColumns.map(({ input }) => input);

  const recipeComponentsForm = useForm({
    mode: 'all', // To make sure that validation is run on every action
    defaultValues: {
      [RECIPES_COMPONENTS_FIELD_ARRAY_NAME]: [],
    },
    resolver: yupResolver(
      buildArraySchema(RECIPES_COMPONENTS_FIELD_ARRAY_NAME, recipeComponentsFormInputs),
    ),
  });

  const { fields, prepend, remove, replace } = useFieldArray({
    control: recipeComponentsForm.control,
    name: RECIPES_COMPONENTS_FIELD_ARRAY_NAME,
    defaultValues: {
      [RECIPES_COMPONENTS_FIELD_ARRAY_NAME]: [],
    },
  });

  const formFields = useWatch({
    name: RECIPES_COMPONENTS_FIELD_ARRAY_NAME,
    control: recipeComponentsForm.control,
  });

  const handleCentralKitchenRecipeStoreEntityMappings = async (recipeId) => {
    const { stores } = props;

    const centralKitchenStores = stores.filter(({ isKitchen }) => isKitchen);

    const storeEntityMappings =
      await centralService.getCentralKitchenRecipeProductionMappingsByEntityId(recipeId);

    const formattedStoreEntityMappings = storeEntityMappings.map(
      ({ id, storeId, entityId, lnkStoreStoreEntityMappingrel }) => ({
        id,
        storeId,
        entityId,
        storeName: get(lnkStoreStoreEntityMappingrel, 'name', ''),
        storeCity: get(lnkStoreStoreEntityMappingrel, 'city', ''),
        storeCountry: get(lnkStoreStoreEntityMappingrel, 'country', ''),
      }),
    );

    setMappedStoreEntityMappings(formattedStoreEntityMappings);
    setInitialMappedStoreEntityMappings(formattedStoreEntityMappings);

    const storeEntityMappingsStoreIds = formattedStoreEntityMappings.map(({ storeId }) => storeId);

    const availableStoreEntityMappingToBeAdd = centralKitchenStores.filter(
      ({ id }) => !storeEntityMappingsStoreIds.includes(id),
    );

    const formattedAvailableMappings = availableStoreEntityMappingToBeAdd.map(
      ({ id, name, city, country }) => ({
        entityId: recipeId,
        storeId: id,
        storeName: name,
        storeCity: city,
        storeCountry: country,
      }),
    );

    setNotMappedStoreEntityMappings(formattedAvailableMappings);
  };

  const handleFetchChannels = async () => {
    const result = await channelService.getChannels();

    setChannels(result);
  };

  const handleFetchInventoryTemplates = async () => {
    const result = await clientService.getInventoryListTemplates(clientId, {
      withMappings: false,
      filterByUserCatalog: true,
    });

    setInventoryListTemplates(result);
  };

  const handleFetchBrands = async () => {
    if (!hasMultipleBrands) {
      return;
    }

    const result = await brandService.getBrandsOfClient(clientId);

    setBrands(result);
  };

  useEffect(() => {
    pageLoading();

    clearState(onEntitiesMappedChange, onActiveSubPageChange, onSelectedRecipeSubPage);

    fetchRecipesByCategories(
      clientId,
      onRecipesAvailableChange,
      setRecipeCategories,
      false,
      isKitchen,
    );

    handleFetchChannels();

    handleFetchInventoryTemplates();

    handleFetchBrands();

    (async function loadData() {
      try {
        await fetchRecipesByCategories(
          clientId,
          onRecipesAvailableChange,
          setRecipeCategories,
          false,
          isKitchen,
        );

        const channels = await channelService.getChannels();
        setChannels(channels);

        const clientILTs = await clientService.getInventoryListTemplates(clientId, {
          withMappings: false,
          filterByUserCatalog: true,
        });
        setInventoryListTemplates(clientILTs);

        if (hasMultipleBrands) {
          const brands = await brandService.getBrandsOfClient(clientId);

          setBrands(brands);
        }

        if (!recipeId || recipeId === RECIPE_ID_CREATE_MODE) {
          if (isDuplicatingRecipe) {
            const [
              { recipe: fetchedRecipe, allergens: fetchedAllergens, product },
              fetchedCompositions,
            ] = await Promise.all([
              recipeService.getRecipe(location.recipe.id, false),
              recipeService.getCompositions(location.recipe.id),
            ]);

            sortCompositions(fetchedCompositions);

            setAssociatedProduct(product || {});
            setCompositions(fetchedCompositions);
            setInitialCompositions(fetchedCompositions);
            setInitialRecipe(fetchedRecipe);
            onRecipeChange(fetchedRecipe);
            onAllergensChange(fetchedAllergens);
            onDuplicationChange(true);

            pageLoaded();
            return;
          }

          if (hasMultipleChannels) {
            setInitialRecipe({ ...recipe, hasMultipleChannels: isKitchen ? false : true });
            onRecipeChange({ ...initialRecipe, hasMultipleChannels: isKitchen ? false : true });
          }
        } else {
          const subPages = isKitchen
            ? [
                RECIPE_COMPOSITION_SUB_PAGE,
                RECIPE_PREPARATION_STEPS_SUB_PAGE,
                RECIPE_PRODUCTION_SUB_PAGE,
                RECIPE_PARENTS_SUB_PAGE,
              ]
            : [
                RECIPE_COMPOSITION_SUB_PAGE,
                RECIPE_PREPARATION_STEPS_SUB_PAGE,
                RECIPE_PARENTS_SUB_PAGE,
              ];
          onActiveSubPageChange(subPages);

          fetchRecipeParents(recipeId, onEntitiesMappedChange);

          const [
            { recipe: fetchedRecipe, allergens: fetchedAllergens, product },
            fetchedCompositions,
          ] = await Promise.all([
            recipeService.getRecipe(recipeId, false),
            recipeService.getCompositions(recipeId),
          ]);

          sortCompositions(fetchedCompositions);

          setAssociatedProduct(product || {});
          setCompositions(fetchedCompositions);
          setInitialCompositions(fetchedCompositions);
          setInitialRecipe(fetchedRecipe);
          onRecipeChange(fetchedRecipe);
          onAllergensChange(fetchedAllergens);

          if (isKitchen && recipeId) {
            await handleCentralKitchenRecipeStoreEntityMappings(recipeId);
          }
        }
        pageLoaded();
      } catch {
        showErrorMessage(i18next.t('ADMIN.RECIPES.LOAD_DETAIL_FAILED'));
        pageDidNotLoad();
      } finally {
        setLoading(false);
      }
    })();
  }, [recipeId]);

  useEffect(() => {
    const { recipeComponents: formEntities } = recipeComponentsForm.getValues();

    const formattedComposition = formatCompositions(formEntities);

    const formattedCostCompositions =
      utilsMethods.formatCompositionsEntityCost(formattedComposition);

    setCompositions(formattedCostCompositions);

    onRecipeCostChange(utilsMethods.getRecipeCost(formattedCostCompositions));

    (async () => {
      const fieldsValidation = await recipeComponentsForm.trigger();
      setIsSaveDisabled(!fieldsValidation);
    })();
  }, [formFields]);

  useEffect(() => {
    if (isDuplication) {
      onRecipeChange({
        ...recipe,
        id: null,
        name: recipe.name + ' - copie',
      });
    }
  }, [isDuplication]);

  useEffect(() => {
    if (!initialRecipe.hasMultipleChannels && recipe.hasMultipleChannels) {
      const { recipeComponents: currentValues } = recipeComponentsForm.getValues();

      const updatedCompositions = currentValues.map((value) => ({
        ...value,
        onSite: true,
        delivery: true,
      }));

      replace(updatedCompositions);
    }
  }, [recipe.hasMultipleChannels]);

  useEffect(() => {
    const updatedAllergensList = compositions.reduce((result, { allergens }) => {
      if (allergens && allergens.length) {
        for (const allergen of allergens) {
          if (!result.some((item) => item.id === allergen.id)) {
            result.push(allergen);
          }
        }
      }

      return result;
    }, []);

    onAllergensChange(updatedAllergensList);
  }, [compositions.length]);

  const isReadOnly = isUserManager(user) || (!recipe.active && recipe.id);

  if (isLoading) {
    return null;
  }

  const handleCallToCreateIngredient = async (ingredient) => {
    pageLoading();

    try {
      let ingredientCategoryId = ingredient.categoryId;

      if (!ingredientCategoryId) {
        const { categories } = await clientService.getCategoriesAndSubCategories(
          clientId,
          CATEGORY_TYPES_OBJECT.INGREDIENT,
        );

        const categoryNone = categories.find(({ name }) => !name);

        ingredientCategoryId = categoryNone.id;
      }

      const newlyCreatedIngredient = await ingredientService.createIngredient(clientId, {
        name: ingredient.name,
        unit: ingredient.unit,
        category: ingredient.category,
        code: ingredient.code,
        cost: ingredient.cost,
        supplierProducts: ingredient.supplierProducts.map(({ id, isUsedForCost }) => ({
          id,
          isUsedForCost,
        })),
        allergenIds: ingredient.allergens.map((allergen) => allergen.id),
        isStrategic: ingredient.isStrategic,
        categoryId: ingredientCategoryId,
      });

      showSuccessMessage(i18next.t('ADMIN.INGREDIENTS.CREATION_SUCCESS'));

      const defaultChannel = channels.find(({ id }) => id === defaultChannelId);

      const composition = {
        allergens: ingredient.allergens,
        entity: newlyCreatedIngredient,
        quantity: 0,
        cost: ingredient.cost,
        channels: recipe.hasMultipleChannels ? channels : [defaultChannel],
      };

      prepend(formatEntity(composition));
      recipeComponentsForm.trigger(RECIPES_COMPONENTS_FIELD_ARRAY_NAME); // Force validate added item to render proper icon status

      await fetchRecipesByCategories(
        clientId,
        onRecipesAvailableChange,
        setRecipeCategories,
        false,
        isKitchen,
      );

      closeGenericModal();
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.CREATION_ERROR'));
    }

    pageLoaded();
  };

  const addEntity = async (item) => {
    prepend({ ...item, quantity: 1 });

    recipeComponentsForm.trigger(RECIPES_COMPONENTS_FIELD_ARRAY_NAME); // Force validate added item to render proper icon status
  };

  const deleteEntity = (index, field) => {
    remove(index);
    recipeComponentsForm.trigger(RECIPES_COMPONENTS_FIELD_ARRAY_NAME);
  };

  return (
    <Container>
      {renderContent({
        ...props,
        recipe,
        recipeCost,
        activeSubPages,
        selectedRecipeSubPage,
        onSelectedRecipeSubPage,
        onRecipeChange,
        recipeCategories,
        isDuplication,
        recipesAvailable,
        allergens,
        isReadOnly,
        entitiesMapped,
        onEntitiesMappedChange,
        clientId,
        handleCallToCreateIngredient,
        warningModalParams,
        setWarningModalParams,
        isSaveDisabled,
        setIsSaveDisabled,
        compositions,
        setCompositions,
        channels,
        initialRecipe,
        associatedProduct,
        isDuplicatingRecipe,
        isKitchen,
        mappedStoreEntityMappings,
        initialMappedStoreEntityMappings,
        setMappedStoreEntityMappings,
        notMappedStoreEntityMappings,
        setNotMappedStoreEntityMappings,
        selectedStoreEntityMappings,
        setSelectedStoreEntityMappings,
        tableColumns,
        recipeComponentsFormInputs,
        recipeComponentsForm,
        formFields,
        addEntity,
        deleteEntity,
        fields,
        replace,
        initialCompositions,
        brands,
        inventoryListTemplates,
        setRecipeCategories,
        setInventoryListTemplates,
      })}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  pageDidNotLoad: () => {
    dispatch(loadingFailure());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDetails);
